import { CLEAR_MESSAGE, SET_MESSAGE } from "../ActionTypes";

export const setMessage = (text, status) => ({
  type: SET_MESSAGE,
  payload: { text, status },
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});
