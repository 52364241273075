import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import {
  clearDepositsData,
  deleteDeposit,
  setDepositsDetail,
  storeDeposit,
} from "../../../redux/actions/DepositAction";
import { useNavigate, useParams } from "react-router";

const DepositsDetailLogic = () => {
  const { detail } = useSelector((state) => state.deposit);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAddDepositModal, setShowAddDepositModal] = useState(false);
  const [deposit, setDeposit] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const { ownerId } = useParams();

  const handleOpenAddDepositModal = () => {
    setShowAddDepositModal(true);
  };
  const handleCloseAddDepositModal = () => {
    setShowAddDepositModal(false);
  };

  const handleOpenDeleteDialog = () => {
    setShowDeleteDialog(true);
  };
  const handleClodeDeleteDialog = () => {
    setShowDeleteDialog(false);
  };
  const back = () => navigate(-1);

  const columns = [
    {
      field: "created_at",
      headerName: "Tanggal",
      width: 300,
      valueGetter: (params) => {
        return format(parseISO(params.row.created_at), "dd-MM-yyyy HH:mm:ss");
      },
    },
    {
      field: "deposits",
      headerName: "Jumlah Deposit",
      width: 300,
      valueGetter: (params) => {
        return params.row.deposits + " Poin";
      },
    },
    {
      field: "action",
      headerName: "Aksi",
      sortable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setDeletedId(params.row.id);
            handleOpenDeleteDialog();
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const AddDepositFields = [
    {
      id: "deposit",
      name: "Jumlah Deposit (Poin)",
      type: "Number",
    },
  ];
  const handleFieldChange = (e) => {
    setDeposit(e.target.value);
  };

  const store = () => {
    dispatch(storeDeposit(detail.id, deposit));
    handleCloseAddDepositModal();
  };

  const deleteSelectedDeposit = () => {
    dispatch(deleteDeposit(deletedId));
    handleClodeDeleteDialog();
  };

  useEffect(() => {
    dispatch(setDepositsDetail(ownerId));

    return () => {
      dispatch(clearDepositsData());
    };
  }, [dispatch, ownerId]);

  return {
    detail,
    columns,
    showAddDepositModal,
    AddDepositFields,
    showDeleteDialog,
    handleCloseAddDepositModal,
    handleOpenAddDepositModal,
    handleFieldChange,
    store,
    handleOpenDeleteDialog,
    handleClodeDeleteDialog,
    deleteSelectedDeposit,
    back,
  };
};

export default DepositsDetailLogic;
