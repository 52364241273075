import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  palette: {
    primary: {
      main: "#212121",
      light: "484848",
      dark: "#000000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#fafafa",
      light: "#ffffff",
      dark: "#c7c7c7",
      contrastText: "#000000",
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
