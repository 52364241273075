import React from "react";
import {
  Alert,
  Box,
  Button,
  Chip,
  Container,
  CssBaseline,
  Grid,
  ImageList,
  ImageListItem,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DormDetailLogic from "./DormDetailLogic";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import LeafletMapStatic from "../../components/LeafletMap/LeafletMapStatic";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

export default function DormDetail() {
  const logic = DormDetailLogic();

  return (
    <Container>
      <CssBaseline />
      {logic.detail && (
        <>
          <Grid container spacing={3} sx={{ mt: 5 }}>
            <Grid item sm={4}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ borderRadius: 5, m: 2 }}
                onClick={logic.back}
              >
                <KeyboardBackspaceIcon sx={{ marginRight: 2 }} />
                Kembali
              </Button>
              <Paper sx={{ p: 2 }}>
                <Chip
                  size="small"
                  icon={
                    logic.detail.active === 1 ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <HighlightOffIcon />
                    )
                  }
                  variant="filled"
                  color={logic.detail.active === 1 ? "success" : "error"}
                  label={logic.detail.active === 1 ? "Aktif" : "Nonaktif"}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography fontWeight="bold">{logic.detail.name}</Typography>
                  <Chip
                    sx={{ m: 0.5 }}
                    variant="outlined"
                    size="small"
                    label={logic.detail.category.category}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    my: 1,
                  }}
                >
                  <LocationOnIcon />
                  <Typography variant="caption">
                    {logic.detail.address}
                  </Typography>
                </Box>
                <Paper sx={{ p: 2, mb: 2 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Fasilitas:
                  </Typography>
                  <Grid container>
                    {logic.detail.facilities.map((facility) => (
                      <Chip
                        sx={{ m: 0.5 }}
                        size="small"
                        key={facility.id}
                        label={facility.facility}
                      />
                    ))}
                  </Grid>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Keterangan:
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {logic.detail.term.term}
                  </Typography>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Harga:
                  </Typography>
                  {logic.detail.prices.map((price) => (
                    <Typography key={price.id} variant="body2">
                      {price.max_price
                        ? new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          }).format(price.price) +
                          " - " +
                          new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          }).format(price.max_price)
                        : new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          }).format(price.price)}
                      /{price.unit}
                    </Typography>
                  ))}
                </Paper>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ mr: 2 }}
                  onClick={logic.handleOpenDeleteDialog}
                >
                  Hapus
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={logic.editDorm}
                >
                  Edit
                </Button>
              </Paper>
            </Grid>
            <Grid item sm={8}>
              <Paper sx={{ p: 2, mb: 2 }}>
                <ImageList cols={3} gap={8}>
                  {logic.detail.photos.map((item, index) => {
                    return (
                      <ImageListItem key={index}>
                        <img
                          src={
                            process.env.REACT_APP_HOST + "photos/" + item.photo
                          }
                          alt={index}
                          loading="lazy"
                        />
                      </ImageListItem>
                    );
                  })}
                </ImageList>
              </Paper>
              <Paper
                sx={{
                  p: 2,
                  height: "430px",
                  maxHeight: "60%",
                }}
              >
                <LeafletMapStatic />
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
      <DeleteDialog
        show={logic.showDeleteDialog}
        close={logic.handleCloseDeleteDialog}
        title="Hapus Kost"
        text="Kost ini"
        confirm={logic.deleteDorm}
      />
      <Snackbar
        open={logic.showSnackbar}
        autoHideDuration={6000}
        onClose={logic.handleCloseSnackbar}
      >
        <Alert
          onClose={logic.handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          Terjadi Kesalahan
        </Alert>
      </Snackbar>
    </Container>
  );
}
