import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";

export default function PriceDialog({ show, close, save }) {
  const [rangeChecked, setRangeChecked] = useState(false);
  const [price, setPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [unit, setUnit] = useState("Bulan");
  const units = ["Hari", "Minggu", "Bulan", "Tahun"];

  return (
    <Dialog
      open={show}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Harga</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Tambah Harga
        </DialogContentText>
        <FormControlLabel
          control={<Checkbox />}
          label="Rentang Harga"
          checked={rangeChecked}
          onChange={(e) => {
            setRangeChecked(e.target.checked);
            !e.target.checked && setMaxPrice("");
          }}
        />
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <TextField
            value={price}
            type="number"
            label="Harga"
            size="small"
            onChange={(e) => setPrice(e.target.value)}
          />
          {rangeChecked && <Typography sx={{ mx: 2 }}>s/d</Typography>}
          {rangeChecked && (
            <TextField
              value={maxPrice}
              type="number"
              label="Harga Max"
              size="small"
              onChange={(e) => setMaxPrice(e.target.value)}
            />
          )}
          <Typography sx={{ mx: 2 }}>Per</Typography>
          <Select
            value={unit}
            label="Lama"
            onChange={(e) => setUnit(e.target.value)}
            size="small"
          >
            {units.map((unit) => (
              <MenuItem key={unit} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Batal</Button>
        <Button
          color="warning"
          autoFocus
          onClick={() => {
            const data = {
              price: price,
              max_price: maxPrice,
              unit: unit,
            };
            save(data);
          }}
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
}
