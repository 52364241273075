import React from "react";
import { Container, CssBaseline, Paper, Typography } from "@mui/material";
import AdminLogic from "./AdminLogic";
import { Chart } from "react-google-charts";

export default function Admin() {
  const logic = AdminLogic();
  return (
    <Container>
      <CssBaseline />
      <Paper sx={{ mt: 5, p: 2 }}>
        <Typography>Selamat Datang, {logic.userProfile?.name}</Typography>
      </Paper>
      <Paper sx={{ p: 2, my: 2 }}>
        <Typography variant="body2" fontWeight="bold">
          Data penayangan:
        </Typography>
        {logic.chartData && (
          <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={logic.chartData}
            options={{
              curveType: "function",
            }}
          />
        )}
      </Paper>
    </Container>
  );
}
