import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import RootReducer from "./reducers/RootReducer";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

const middleware = [thunk];
const store = createStore(
  RootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
