import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  FAILED_DELETE_RECOMENDATION,
  FAILED_LOAD_RECOMENDATIONS,
  LOADING,
  RECOMENDATION_DELETED,
  RECOMENDATION_LOADED,
} from "../../../redux/ActionTypes";
import recomendationService from "../../../services/RecomendationService";

const RecomendationLogic = () => {
  const dispatch = useDispatch();
  const [recomendedDorms, setRecomendedDorms] = useState(null);

  const tableColumns = [
    {
      id: "id",
      Header: "Id",
      accessor: "id",
      disableGlobalFilter: true,
    },
    {
      Header: "Nama Kost/Kontrakan",
      accessor: "name",
    },
    {
      Header: "Alamat",
      accessor: "address",
    },
    {
      Header: "Ditambahkan",
      accessor: "created_at",
    },
  ];
  const hiddenColumns = ["id"];

  const tableActionButtons = [
    {
      name: "Hapus",
      variant: "contained",
      action: (row) => deleteRecomendation(row.id),
    },
  ];

  const loadRecomendation = useCallback(() => {
    dispatch({ type: LOADING });
    recomendationService
      .indexRecomendation()
      .then((res) => {
        dispatch({ type: RECOMENDATION_LOADED });
        setRecomendedDorms(res.data.data);
      })
      .catch(() => dispatch({ type: FAILED_LOAD_RECOMENDATIONS }));
  }, [dispatch]);

  const deleteRecomendation = (id) => {
    dispatch({ type: LOADING });
    recomendationService
      .destroyRecomendation(id)
      .then(() => {
        dispatch({ type: RECOMENDATION_DELETED });
        loadRecomendation();
      })
      .catch(() => dispatch({ type: FAILED_DELETE_RECOMENDATION }));
  };

  useEffect(() => {
    loadRecomendation();
    return () => {};
  }, [loadRecomendation]);

  return {
    recomendedDorms,
    tableColumns,
    hiddenColumns,
    tableActionButtons,
  };
};

export default RecomendationLogic;
