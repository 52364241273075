import api from "./Api";

const addPrice = (data) => api.post("price", data);
const deletePrice = (id) => api.delete("price/" + id);

const priceService = {
  addPrice,
  deletePrice,
};

export default priceService;
