import api from "./Api";

const getAllFacilities = (onSuccess, onError) => {
  api
    .get("facility")
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const addNewFacility = (facility, onSuccess, onError) => {
  api
    .post("facility", { facility })
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const deleteFacility = (id, onSuccess, onError) => {
  api
    .delete("facility/" + id)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const addDormFacility = (dorm_id, facility_id, onSuccess, onError) => {
  api
    .post("add-dorm-facility", { dorm_id, facility_id })
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const removeDormFacilities = (dorm_id, onSuccess, onError) => {
  api
    .post("remove-dorm-facilities", { dorm_id })
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const facilityService = {
  getAllFacilities,
  addNewFacility,
  deleteFacility,
  addDormFacility,
  removeDormFacilities,
};

export default facilityService;
