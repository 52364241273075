import api from "./Api";

const getAllDeposits = (onSuccess, onError) => {
  api
    .get("deposit")
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const storeDeposit = (data, onSuccess, onError) => {
  api
    .post("deposit", data)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const showDeposits = (ownerId, onSuccess, onError) => {
  api
    .get("deposit/" + ownerId)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const deleteDeposit = (id, onSuccess, onError) => {
  api
    .delete("deposit/" + id)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const depositService = {
  getAllDeposits,
  storeDeposit,
  showDeposits,
  deleteDeposit,
};

export default depositService;
