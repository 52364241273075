export const RESTART_AUTH_RESPONSE = "RESTART_AUTH_RESPONSE";
export const LOADING = "LOADING";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const CODE_ERROR = "CODE_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const LOAD_PROFILE_SUCCESS = "LOAD_PROFILE_SUCCESS";
export const LOAD_PROFILE_ERROR = "LOAD_PROFILE_ERROR";
export const EMAIL_NOT_VERIFIED = "EMAIL_NOT_VERIFIED";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const LOAD_PROVINCE_SUCCESS = "LOAD_PROVINCE_SUCCESS";
export const SET_PROVINCE = "SET_PROVINCE";
export const SET_CITY = "SET_CITY";
export const SET_DISTRICT = "SET_DISTRICT";
export const SET_VILLAGE = "SET_VILLAGE";
export const CLEAR_REGION_DATA = "CLEAR_REGION_DATA";

export const SUBMIT_PERSONAL_DATA_SUCCESS = "SUBMIT_PERSONAL_DATA_SUCCESS";
export const SUBMIT_PERSONAL_DATA_ERROR = "SUBMIT_PERSONAL_DATA_ERROR";
export const LOAD_ALL_DORM_SUCCESS = "LOAD_ALL_DORM_SUCCESS";
export const LOAD_ALL_DORM_ERROR = "LOAD_ALL_DORM_ERROR";
export const STORE_DORM_SUCCESS = "STORE_DORM_SUCCESS";
export const UPDATE_DROM_SUCCESS = "UPDATE_DROM_SUCCESS";
export const CLEAR_DORM_DATA = "CLEAR_DORM_DATA";
export const LOAD_ALL_FACILITIES_SUCCESS = "LOAD_ALL_FACILITIES_SUCCESS";
export const LOAD_ALL_FACILITIES_ERROR = "LOAD_ALL_FACILITIES_ERROR";
export const CLEAR_FACILITY_DATA = "CLEAR_FACILITY_DATA";
export const ADD_NEW_FACILITY_SUCCESS = "ADD_NEW_FACILITY_SUCCESS";
export const DELETE_FACILITY_SUCCESS = "DELETE_FACILITY_SUCCESS";
export const LOAD_ALL_OWNERS_SUCCESS = "LOAD_ALL_OWNERS_SUCCESS";
export const CLEAR_OWNER_DATA = "CLEAR_OWNER_DATA";
export const SHOW_OWNER_SUCCESS = "SHOW_OWNER_SUCCESS";
export const SHOW_DORM_SUCCESS = "SHOW_DORM_SUCCESS";
export const SET_DORM_DETAIL_SUCCESS = "SET_DORM_DETAIL_SUCCESS";
export const MAP_MARKED = "MAP_MARKED";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const LOAD_ALL_CATEGORIES_SUCCESS = "LOAD_ALL_CATEGORIES_SUCCESS";
export const ADD_NEW_CATEGORY_SUCCESS = "ADD_NEW_CATEGORY_SUCCESS";
export const CLEAR_CATEGORY_DATA = "CLEAR_CATEGORY_DATA";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const SET_DEPOSITS_DETAIL_SUCCESS = "SET_DEPOSITS_DETAIL_SUCCESS";
export const CLEAR_DEPOSITS_DATA = "CLEAR_DEPOSITS_DATA";
export const STORE_DEPOSIT_SUCCESS = "STORE_DEPOSIT_SUCCESS";
export const USERS_DEPOSIT_LOADED = "USERS_DEPOSIT_LOADED";
export const DELETE_DEPOSIT_SUCCESS = "DELETE_DEPOSIT_SUCCESS";
export const EMAIL_SENT = "EMAIL_SENT";
export const FAILED_SEND_EMAIL = "FAILED_SEND_EMAIL";
export const RECOMENDATION_LOADED = "RECOMENDATION_LOADED";
export const FAILED_LOAD_RECOMENDATIONS = "FAILED_LOAD_RECOMENDATIONS";
export const RECOMENDATION_DELETED = "RECOMENDATION_DELETED";
export const FAILED_DELETE_RECOMENDATION = "FAILED_DELETE_RECOMENDATION";
export const ADDED_TO_RECOMENDATION = "ADDED_TO_RECOMENDATION";
export const FAILED_TO_ADD_RECOMENDATION = "FAILED_TO_ADD_RECOMENDATION";
