import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";

export default function FormDialog({
  show,
  close,
  dialogTitle,
  dialogContentText,
  fields,
  handleFieldChange,
  submit,
}) {
  return (
    <Dialog open={show} onClose={close} fullWidth>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContentText}</DialogContentText>
        {fields.map((field) => (
          <TextField
            key={field.id}
            margin="dense"
            id={field.id}
            label={field.name}
            type={field.type}
            fullWidth
            variant="standard"
            value={field.value}
            onChange={handleFieldChange}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>
          Batal
        </Button>
        <Button onClick={submit}>
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
}
