import { useCallback, useEffect, useState } from "react";
import ownerVerificationService from "../../../services/OwnerVerificationService";
import Button from "@mui/material/Button";
import { Chip } from "@mui/material";

const OwnerVerificationLogic = () => {
  const [ownerVerifications, setOwnerVerifications] = useState();
  const [verificationHistory, setVerificationHistory] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [open, setOpen] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [showAcceptDialog, setShowAcceptDialog] = useState(false);
  const [rejectDescription, setRejectDescription] = useState();
  const [selectedVerificationId, setSelectedVerificationId] = useState();

  const handleOpen = (image) => {
    ownerVerificationService.displayIdCard(image).then((res) => {
      var blobURL = URL.createObjectURL(res.data);
      setSelectedImage(blobURL);
      setOpen(true);
    });
  };

  const handleClose = () => {
    setSelectedImage();
    setOpen(false);
  };

  const handleOpenDeleteDialog = (id) => {
    setSelectedVerificationId(id);
    setShowDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setSelectedVerificationId();
    setShowDeleteDialog(false);
  };

  const handleOpenRejectDialog = (id) => {
    setSelectedVerificationId(id);
    setShowRejectDialog(true);
  };
  const handleCloseRejectDialog = () => {
    setSelectedVerificationId();
    setShowRejectDialog(false);
  };

  const handleOpenAcceptDialog = (id) => {
    setSelectedVerificationId(id);
    setShowAcceptDialog(true);
  };
  const handleCloseAcceptDialog = () => {
    setSelectedVerificationId();
    setShowAcceptDialog(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Nama",
      width: 300,
      valueGetter: (params) => {
        return params.row.user.name;
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      valueGetter: (params) => {
        return params.row.user.email;
      },
    },
    { field: "phone", headerName: "Nomor HP", width: 200 },
    {
      field: "id_card",
      headerName: "Identitas",
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => handleOpen(params.row.id_card)}
        >
          Lihat
        </Button>
      ),
    },
    {
      field: "accept",
      headerName: "Accept",
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="success"
          onClick={() => handleOpenAcceptDialog(params.row.id)}
        >
          Accept
        </Button>
      ),
    },
    {
      field: "reject",
      headerName: "Reject",
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="warning"
          onClick={() => handleOpenRejectDialog(params.row.id)}
        >
          Reject
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => handleOpenDeleteDialog(params.row.id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  const historyColumns = [
    {
      field: "name",
      headerName: "Nama",
      width: 300,
      valueGetter: (params) => {
        return params.row.user.name;
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      valueGetter: (params) => {
        return params.row.user.email;
      },
    },
    { field: "phone", headerName: "Nomor HP", width: 200 },
    {
      field: "id_card",
      headerName: "Identitas",
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => handleOpen(params.row.id_card)}
        >
          Lihat
        </Button>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          color={
            params.row.status === "accepted"
              ? "success"
              : params.row.status === "rejected"
              ? "warning"
              : "error"
          }
        />
      ),
    },
    {
      field: "description",
      headerName: "Keterangan",
      width: 500,
      valueGetter: (params) => {
        return params.row.description;
      },
    },
  ];

  const rejectFields = [
    {
      id: "description",
      name: "Keterangan (Alasan Penolakan)",
      type: "Text",
    },
  ];

  const handleRejectDescriptionChange = (e) =>
    setRejectDescription(e.target.value);

  const getInitialData = useCallback(async () => {
    try {
      const resVerifcations =
        await ownerVerificationService.getAllOwnerVerifications();
      setOwnerVerifications(resVerifcations.data.data);

      const resHistory =
        await ownerVerificationService.getVerificationHistory();
      setVerificationHistory(resHistory.data.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const accept = () =>
    ownerVerificationService
      .updateVerification(selectedVerificationId, { status: "accepted" })
      .then(() => {
        handleCloseAcceptDialog();
        getInitialData();
      });

  const reject = () =>
    ownerVerificationService
      .updateVerification(selectedVerificationId, {
        status: "rejected",
        description: rejectDescription,
      })
      .then(() => {
        handleCloseRejectDialog();
        getInitialData();
      });

  const deleteVerification = () =>
    ownerVerificationService
      .updateVerification(selectedVerificationId, { status: "deleted" })
      .then(() => {
        handleCloseDeleteDialog();
        getInitialData();
      });

  useEffect(() => {
    getInitialData();
    return () => {};
  }, [getInitialData]);

  return {
    ownerVerifications,
    verificationHistory,
    columns,
    historyColumns,
    selectedImage,
    open,
    showDeleteDialog,
    showRejectDialog,
    showAcceptDialog,
    rejectFields,
    handleOpen,
    handleClose,
    handleCloseDeleteDialog,
    handleCloseRejectDialog,
    handleCloseAcceptDialog,
    handleRejectDescriptionChange,
    accept,
    reject,
    deleteVerification,
  };
};

export default OwnerVerificationLogic;
