import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDorm } from "../../../redux/actions/DormAction";
import { useNavigate } from "react-router";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import recomendationService from "../../../services/RecomendationService";
import {
  ADDED_TO_RECOMENDATION,
  FAILED_TO_ADD_RECOMENDATION,
  LOADING,
} from "../../../redux/ActionTypes";

const DormLogic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dormList } = useSelector((state) => state.dorm);

  const dormDetail = (dorm) => {
    navigate("/admin/dorm-detail", { state: { dormId: dorm.id } });
  };

  const columns = [
    {
      id: "id",
      Header: "Id",
      accessor: "id",
      disableGlobalFilter: true,
    },
    {
      Header: "Nama",
      accessor: "name",
    },
    {
      Header: "Alamat",
      accessor: "address",
    },
    {
      Header: "Pemilik",
      accessor: "owner",
      Cell: ({ cell: { value } }) => value.user.email,
    },
    {
      Header: "Rekomendasi",
      accessor: "recomendation",
      Cell: ({ cell }) => {
        return cell.row.values.recomendation ? (
          <Typography variant="body2">Direkomendasikan</Typography>
        ) : (
          <Button
            onClick={() => addToRecomendation(cell.row.values.id)}
            size="small"
            variant="contained"
          >
            Tambahkan
          </Button>
        );
      },
    },
  ];
  const hiddenColumns = ["id"];

  const tableActionButtons = [
    {
      name: "Detail",
      variant: "contained",
      action: (row) => dormDetail(row),
    },
  ];

  const addToRecomendation = (id) => {
    dispatch({ type: LOADING });
    recomendationService
      .storeDormRecomendation({ dorm_id: id })
      .then(() => {
        dispatch({ type: ADDED_TO_RECOMENDATION });
        dispatch(getAllDorm());
      })
      .catch(() => dispatch({ type: FAILED_TO_ADD_RECOMENDATION }));
  };

  useEffect(() => {
    dispatch(getAllDorm());
  }, [dispatch]);

  return {
    dormList,
    columns,
    tableActionButtons,
    hiddenColumns,
  };
};

export default DormLogic;
