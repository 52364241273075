import facilityService from "../../services/FacilityService";
import {
  ADD_NEW_FACILITY_SUCCESS,
  CLEAR_FACILITY_DATA,
  CODE_ERROR,
  DELETE_FACILITY_SUCCESS,
  LOADING,
  LOAD_ALL_FACILITIES_SUCCESS,
} from "../ActionTypes";

export const getAllFacilities = () => (dispatch) => {
  dispatch({ type: LOADING });
  facilityService.getAllFacilities((res) => {
    dispatch({
      type: LOAD_ALL_FACILITIES_SUCCESS,
      payload: res,
    });
  });
};

export const addNewFacility = (facility, onSuccess) => (dispatch) => {
  dispatch({ type: LOADING });
  facilityService.addNewFacility(
    facility,
    () => {
      dispatch({
        type: ADD_NEW_FACILITY_SUCCESS,
      });
      onSuccess();
    },
    () =>
      dispatch({
        type: CODE_ERROR,
      })
  );
};

export const deleteFacility = (id, onSuccess) => (dispatch) => {
  dispatch({ type: LOADING });
  facilityService.deleteFacility(
    id,
    () => {
      dispatch({
        type: DELETE_FACILITY_SUCCESS,
      });
      onSuccess();
    },
    () =>
      dispatch({
        type: CODE_ERROR,
      })
  );
};

export const clearFacilityData = () => ({
  type: CLEAR_FACILITY_DATA,
});
