import api from "./Api";

export const RegisterUserService = (credentials, onSuccess, onError) => {
  api
    .post("users/register", credentials)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

export const LoginUserService = (credentials, onSuccess, onError) => {
  api
    .post("users/login", credentials)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error.response));
};

export const LogOutUserService = (onSuccess, onError) => {
  api
    .get("users/logout")
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

export const ResendEmailVerification = () => api.post("email/resend");
