import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../redux/actions/CategoryAction";
import {
  setDormDetail,
  showDorm,
  updateDorm,
} from "../../redux/actions/DormAction";
import { getAllFacilities } from "../../redux/actions/FacilityAction";
import { CODE_ERROR, LOADING } from "../../redux/ActionTypes";
import { useLocation, useNavigate } from "react-router";
import photoService from "../../services/PhotoService";
import dormService from "../../services/DormService";
import priceService from "../../services/PriceService";
import {
  clearRegionData,
  markLocation,
  setVillage,
} from "../../redux/actions/RegionAction";

const EditDormLogic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { detail } = useSelector((state) => state.dorm);
  const { pinpoint } = useSelector((state) => state.region);
  const { facilityList } = useSelector((state) => state.facility);
  const { categoryList } = useSelector((state) => state.category);
  const [name, setName] = useState("");
  const [facilities, setFacilities] = useState("");
  const [category, setCategory] = useState(null);
  const [term, setTerm] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedImage, setSelectedImage] = useState([]);
  const inputRef = useRef(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState({
    severity: "error",
    text: "",
  });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showDeletePriceDialog, setShowDeletePriceDialog] = useState(false);
  const [deletedImage, setDeletedImage] = useState(null);
  const [deletedPrice, setDeletedPrice] = useState(null);
  const [showPriceDialog, setShowPriceDialog] = useState(false);

  const handleOpenPriceDialog = () => setShowPriceDialog(true);
  const handleClosePriceDialog = () => setShowPriceDialog(false);

  const back = () => {
    navigate(-1);
  };

  const savePrice = (data) => {
    let price = data;
    price.dorm_id = detail.id;
    priceService
      .addPrice(price)
      .then(() => {
        dispatch(showDorm(detail.id));
        handleClosePriceDialog();
        setMessage({
          severity: "success",
          text: "Harga Berhasil Ditambahkan",
        });
        handleOpenSnackbar();
      })
      .catch(() => {
        setMessage({
          severity: "error",
          text: "Gagal Menambahkan Harga",
        });
        handleOpenSnackbar();
      });
  };

  const handleOpenDeleteDialog = (image_id) => {
    setDeletedImage(image_id);
    setShowDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const handleOpenDeletePriceDialog = (id) => {
    setDeletedPrice(id);
    setShowDeletePriceDialog(true);
  };

  const handleCloseDeletePriceDialog = () => {
    setShowDeletePriceDialog(false);
  };

  const handleOpenSnackbar = () => {
    setShowSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const selectFacility = (facility_id) => {
    setFacilities([...facilities, facility_id]);
  };

  const deselectFacility = (facility_id) => {
    setFacilities(
      facilities.filter(function (facility) {
        return facility !== facility_id;
      })
    );
  };

  // This function will be triggered when the file field change
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage([...selectedImage, e.target.files[0]]);
    }
  };

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = (removedImage) => {
    setSelectedImage(
      selectedImage.filter(function (image) {
        return image !== removedImage;
      })
    );
  };

  const handleSwitchChange = (event) => {
    event.target.checked ? activateDorm() : deactivateDorm();
  };

  const deactivateDorm = () => {
    dispatch({ type: LOADING });
    dormService.deactivateDorm(
      detail.id,
      () => {
        const dormDetail = {
          ...detail,
          active: 0,
        };
        dispatch(setDormDetail(dormDetail));
      },
      () => {
        dispatch({ type: CODE_ERROR });
        console.log("error deactivating dorm");
      }
    );
  };

  const activateDorm = () => {
    dispatch({ type: LOADING });
    dormService.activateDorm(
      detail.id,
      () => {
        const dormDetail = {
          ...detail,
          active: 1,
        };
        dispatch(setDormDetail(dormDetail));
      },
      () => {
        dispatch({ type: CODE_ERROR });
        console.log("error activating dorm");
      }
    );
  };

  const showPhoto = () => {
    dispatch({ type: LOADING });
    photoService.showPhoto(
      detail.id,
      (res) => {
        console.log("photo loaded");
        const dormDetail = {
          ...detail,
          photos: res.data,
        };
        dispatch(setDormDetail(dormDetail));
      },
      () => {
        dispatch({ type: CODE_ERROR });
        console.log("error loading photo");
      }
    );
  };

  const deleteImage = () => {
    photoService.deletePhoto(
      deletedImage,
      () => {
        setMessage({
          severity: "success",
          text: "Foto berhasil dihapus",
        });
        handleCloseDeleteDialog();
        handleOpenSnackbar();
        showPhoto();
      },
      () => {
        setMessage({
          severity: "error",
          text: "Foto gagal dihapus",
        });
        handleCloseDeleteDialog();
        handleOpenSnackbar();
      }
    );
  };

  const deletePrice = () => {
    priceService
      .deletePrice(deletedPrice)
      .then(() => {
        dispatch(showDorm(detail.id));
        setMessage({
          severity: "success",
          text: "Harga berhasil dihapus",
        });
        handleCloseDeletePriceDialog();
        handleOpenSnackbar();
      })
      .catch(() => {
        setMessage({
          severity: "error",
          text: "Harga gagal dihapus",
        });
        handleCloseDeletePriceDialog();
        handleOpenSnackbar();
      });
  };

  const submit = () => {
    let phoneNumber = phone.trim();
    if (phoneNumber.startsWith("+62")) {
      phoneNumber = "62" + phoneNumber.slice(3);
    } else if (phoneNumber.startsWith("0")) {
      phoneNumber = "62" + phoneNumber.slice(1);
    }
    const dormData = {
      name: name,
      map_point: JSON.stringify(pinpoint),
      category_id: category,
      address: address,
      phone: phoneNumber,
    };
    if (name === "") {
      setMessage({
        severity: "error",
        text: "Nama kost belum diisi",
      });
      handleOpenSnackbar();
    } else if (category === "") {
      setMessage({
        severity: "error",
        text: "Kategori belum dipilih",
      });
      handleOpenSnackbar();
    } else if (!address) {
      setMessage({ severity: "error", text: "Alamat belum diisi" });
      handleOpenSnackbar();
    } else if (!pinpoint) {
      setMessage({ severity: "error", text: "lokasi pada peta belum dipilih" });
      handleOpenSnackbar();
    } else if (!phone) {
      setMessage({ severity: "error", text: "No. Telepon belum diisi" });
      handleOpenSnackbar();
    } else if (term === "") {
      setMessage({
        severity: "error",
        text: "Peraturan belum diisi",
      });
      handleOpenSnackbar();
    } else {
      dispatch(
        updateDorm(
          detail.id,
          dormData,
          facilities,
          term,
          selectedImage,
          navigate
        )
      );
    }
  };

  const setInitialData = useCallback(() => {
    if (detail) {
      let fac = [];
      detail.facilities.map((facility) => fac.push(facility.id));
      setFacilities(fac);

      const location = JSON.parse(detail.map_point);
      const meta = {
        lat: location.lat,
        long: location.lng,
      };
      dispatch(setVillage(detail.village.code, JSON.stringify(meta)));
      dispatch(markLocation(location));
      setName(detail.name);
      setCategory(detail.category.id);
      setAddress(detail.address);
      setPhone(detail.phone);
      setTerm(detail.term.term);
    }
  }, [detail, dispatch]);

  useEffect(() => {
    dispatch(showDorm(state.dormId));
    dispatch(getAllFacilities());
    dispatch(getAllCategories());
    return () => {
      dispatch(clearRegionData());
    };
  }, [dispatch, state.dormId]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  return {
    detail,
    name,
    facilityList,
    categoryList,
    selectedImage,
    inputRef,
    category,
    showSnackbar,
    message,
    facilities,
    term,
    showDeleteDialog,
    showDeletePriceDialog,
    showPriceDialog,
    address,
    phone,
    setAddress,
    setPhone,
    setName,
    selectFacility,
    deselectFacility,
    setTerm,
    imageChange,
    removeSelectedImage,
    handleUpload,
    setCategory,
    submit,
    handleCloseSnackbar,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    deleteImage,
    handleSwitchChange,
    handleOpenDeletePriceDialog,
    handleCloseDeletePriceDialog,
    deletePrice,
    handleOpenPriceDialog,
    handleClosePriceDialog,
    savePrice,
    back,
  };
};

export default EditDormLogic;
