import api from "./Api";

const getAllDorms = (onSuccess, onError) => {
  api
    .get("dorm")
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const showDorm = (id, onSuccess, onError) => {
  api
    .get("dorm/" + id)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const storeDorm = (
  dorm,
  photos,
  facilities,
  prices,
  term,
  onSuccess,
  onError
) => {
  const form = new FormData();
  form.append("dorm", JSON.stringify(dorm));
  photos.map((photo) => form.append("photos[]", photo));
  form.append("facilities", JSON.stringify(facilities));
  form.append("prices", JSON.stringify(prices));
  form.append("term", term);
  api
    .post("dorm", form)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const updateDorm = (dormId, data, onSuccess, onError) => {
  api
    .put("dorm/" + dormId, data)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const activateDorm = (id, onSuccess, onError) => {
  api
    .post("activate-dorm", { id })
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const deactivateDorm = (id, onSuccess, onError) => {
  api
    .post("deactivate-dorm", { id })
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const deleteDorm = (id, onSuccess, onError) => {
  api
    .delete("dorm/" + id)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const dormService = {
  getAllDorms,
  showDorm,
  storeDorm,
  updateDorm,
  activateDorm,
  deactivateDorm,
  deleteDorm,
};

export default dormService;
