import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RegisterAction } from "../../redux/actions/AuthAction";
import { clearMessage } from "../../redux/actions/MessageAction";
import { useNavigate } from "react-router";

export const RegisterLogic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.message);
  const errors =
    typeof message.text === "string"
      ? message.text
      : message.text
      ? Object.values(message.text)
      : null;
  //const authResponse = useSelector(state=>state.userAuth.authResponse);
  const [fields, setState] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    role: "mitra",
  });

  const handleFieldChange = (e) => {
    setState({
      ...fields,
      [e.target.id]: e.target.value,
    });
  };

  const UserRegister = (e) => {
    e.preventDefault();
    const passwordMatch = checkPasswordMatch(
      fields.password,
      fields.password_confirmation
    );
    if (passwordMatch === true) {
      alert("passwords dont match. please check your password again");
      return;
    }
    dispatch(RegisterAction(fields, navigate));
  };

  const checkPasswordMatch = (password, password_confirmation) => {
    return password !== password_confirmation ? true : false;
  };

  useEffect(() => {
    return () => dispatch(clearMessage());
  }, [dispatch]);

  return { handleFieldChange, UserRegister, message, errors };
};

export default RegisterLogic;
