import * as ActionTypes from "../ActionTypes";
import {
  RegisterUserService,
  LoginUserService,
  LogOutUserService,
} from "../../services/AuthService";
import { setMessage } from "./MessageAction";
import { loadProfileAction } from "./UserActions";

export const RegisterAction = (credentials, navigate) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });
    RegisterUserService(
      credentials,
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: ActionTypes.SIGNUP_SUCCESS, res });
          dispatch(LoginAction(credentials, navigate));
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: ActionTypes.SIGNUP_ERROR, res });
          dispatch(setMessage(res.message, "error"));
        }
      },
      (error) => {
        error?.data?.message
          ? dispatch(setMessage(error.data.message, "error"))
          : dispatch({ type: ActionTypes.CODE_ERROR });
      }
    );
  };
};

export const LoginAction = (credentials, navigate) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });
    LoginUserService(
      credentials,
      (data) => {
        localStorage.setItem("user-token", data.token);
        if (data.data.email_verified_at) {
          dispatch({ type: ActionTypes.LOGIN_SUCCESS });
          dispatch(loadProfileAction(navigate));
        } else {
          dispatch({ type: ActionTypes.SIGNUP_SUCCESS, res: data.data });
          navigate("/user/email-verification");
        }
      },
      (error) => {
        error?.data?.message
          ? dispatch(setMessage(error.data.message, "error"))
          : dispatch({ type: ActionTypes.CODE_ERROR });
      }
    );
  };
};

export const LogoutAction = (navigate) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });
    LogOutUserService(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          localStorage.removeItem("user-token");
          dispatch({ type: ActionTypes.LOGOUT_SUCCESS, res });
          dispatch({ type: ActionTypes.CLEAR_USER_DATA });
          dispatch({ type: ActionTypes.CLEAR_OWNER_DATA });
          navigate("/");
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: ActionTypes.LOGOUT_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
};
