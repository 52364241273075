import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function DeleteDialog({ title, text, show, close, confirm }) {
  const message = useSelector((state) => state.message);
  return (
    <Dialog
      open={show}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Apakah anda yakin akan menghapus {text}?
        </DialogContentText>
        {message.status && (
          <Alert sx={{ width: "100%" }} severity={message.status}>
            {message.text}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>
          Batal
        </Button>
        <Button color="warning" autoFocus onClick={confirm}>
          Hapus
        </Button>
      </DialogActions>
    </Dialog>
  );
}
