import {
  Container,
  CssBaseline,
  Paper,
  Typography,
  Box,
  Button,
} from "@mui/material";
import React from "react";
import DepositsDetailLogic from "./DepositsDetailLogic";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DataGrid } from "@mui/x-data-grid";
import FormDialog from "../../../components/FormDialog/FormDialog";
import DeleteDialog from "../../../components/DeleteDialog/DeleteDialog";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

export default function DepositsDetail() {
  const logic = DepositsDetailLogic();
  return (
    <Container>
      <CssBaseline />
      <Button
        variant="contained"
        color="secondary"
        sx={{ borderRadius: 5, mt: 5, mb: 2 }}
        onClick={logic.back}
      >
        <KeyboardBackspaceIcon sx={{ marginRight: 2 }} />
        Kembali
      </Button>
      {logic.detail && (
        <>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography fontWeight="bold" variant="body2">
                  {logic.detail.user.name}
                </Typography>
                <Typography variant="caption">
                  ({logic.detail.user.email})
                </Typography>
              </Box>
              <Typography variant="h5">Deposit:</Typography>
              <Typography variant="h4">
                {logic.detail.remaining} Poin
              </Typography>
            </Box>
            <Box>
              <Button
                sx={{ borderRadius: 10, boxShadow: 5 }}
                onClick={logic.handleOpenAddDepositModal}
              >
                <AddCircleOutlineIcon />
                Tambah Deposit
              </Button>
            </Box>
          </Paper>
          <Paper
            sx={{
              p: 2,
              mt: 2,
            }}
          >
            <Typography variant="h6">Riwayat Deposit</Typography>
            <div style={{ height: 300, width: "100%" }}>
              <DataGrid
                rows={logic.detail.deposits}
                columns={logic.columns}
                checkboxSelection
              />
            </div>
          </Paper>
          <FormDialog
            show={logic.showAddDepositModal}
            close={logic.handleCloseAddDepositModal}
            dialogTitle="Tambah Deposit"
            dialogContentText="Masukkan Jumlah Deposit"
            fields={logic.AddDepositFields}
            handleFieldChange={logic.handleFieldChange}
            submit={logic.store}
          />
          <DeleteDialog
            show={logic.showDeleteDialog}
            close={logic.handleClodeDeleteDialog}
            title="Hapus Deposit"
            text="deposit ini"
            confirm={logic.deleteSelectedDeposit}
          />
        </>
      )}
    </Container>
  );
}
