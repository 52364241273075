import api from "./Api";

const getAllCategories = (onSuccess, onError) => {
  api
    .get("category")
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const addNewCategory = (category, onSuccess, onError) => {
  api
    .post("category", { category })
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const deleteCategory = (id, onSuccess, onError) => {
  api
    .delete("category/" + id)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const categoryService = {
  getAllCategories,
  addNewCategory,
  deleteCategory,
};

export default categoryService;
