import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EMAIL_SENT,
  FAILED_SEND_EMAIL,
  LOADING,
} from "../../../redux/ActionTypes";
import { ResendEmailVerification } from "../../../services/AuthService";

const EmailVerificationLogic = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState("");
  const { authResponse } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();

  const closeDialog = () => setShowDialog(false);
  const resend = () => {
    dispatch({ type: LOADING });
    ResendEmailVerification()
      .then(() => {
        setMessage("Email berhasil dikirim ulang");
        dispatch({ type: EMAIL_SENT });
        setShowDialog(true);
      })
      .catch(() => {
        setMessage("Gagal mengirim ulang email");
        dispatch({ type: FAILED_SEND_EMAIL });
        setShowDialog(true);
      });
  };

  return {
    showDialog,
    message,
    authResponse,
    closeDialog,
    resend,
  };
};

export default EmailVerificationLogic;
