import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import DormCardLogic from "./DormCardLogic";

export default function DormCard({
  thumbnail,
  name,
  village,
  facilities,
  category,
  onClick,
}) {
  const logic = DormCardLogic(village);
  return (
    <Card sx={{ maxWidth: 250, height: "100%", borderRadius: 5 }}>
      <CardActionArea
        onClick={onClick}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 1,
        }}
      >
        <>
          <CardMedia
            component="img"
            height="140"
            image={thumbnail ? thumbnail : "/static/images/dorm.jpg"}
            alt="dorm"
            sx={{
              borderRadius: 3,
            }}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {logic.address}
            </Typography>
            <Typography variant="caption" fontWeight="bold">
              Fasilitas:
            </Typography>
            <Grid container>
              {facilities.map((facility) => (
                <Chip
                  sx={{ m: 0.5 }}
                  size="small"
                  key={facility.id}
                  label={facility.facility}
                />
              ))}
            </Grid>
          </CardContent>
        </>
        <Chip size="small" label={category} variant="outlined" />
      </CardActionArea>
    </Card>
  );
}
