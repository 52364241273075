import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import DormCard from "../../../components/DormCard.js/DormCard";
import UserLogic from "./UserLogic";
import { Chart } from "react-google-charts";

export default function User() {
  const userLogic = UserLogic();
  return (
    <Container component="main">
      <CssBaseline />
      <Grid container spacing={3} sx={{ mt: 5 }}>
        <Grid item sm={4}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              padding: 2,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {userLogic.userProfile?.name && (
              <Avatar
                {...userLogic.stringAvatar(userLogic.userProfile?.name)}
              />
            )}
            <Typography component="h1" variant="h6">
              {userLogic.userProfile?.name}
            </Typography>
            <Typography>{userLogic.userProfile?.email}</Typography>
          </Paper>
        </Grid>
        <Grid item sm={8}>
          {userLogic.chartData[1] && (
            <Paper
              elevation={3}
              sx={{
                padding: 2,
              }}
            >
              <Chart
                chartType="BarChart"
                data={userLogic.chartData}
                width="100%"
              />
            </Paper>
          )}
        </Grid>
        <Grid item sm={12}>
          <Paper
            elevation={3}
            sx={{
              padding: 2,
            }}
          >
            <Typography fontWeight="bold" variant="body1">
              Data Kost:
            </Typography>
            {userLogic.userProfile?.owner ? (
              <>
                <Grid container sx={{ m: 1 }}>
                  {userLogic.userProfile.owner.dorms.map((dorm) => (
                    <Grid item key={dorm.id} sx={{ m: 1 }}>
                      <DormCard
                        thumbnail={
                          dorm.photos[0]
                            ? process.env.REACT_APP_HOST +
                              "photos/" +
                              dorm.photos[0].photo
                            : null
                        }
                        name={dorm.name}
                        village={dorm.village}
                        facilities={dorm.facilities}
                        category={dorm.category.category}
                        onClick={() => userLogic.dormDetail(dorm)}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Button variant="contained" onClick={userLogic.addDorm}>
                  Tambahkan Kost/Kontrakan
                </Button>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ my: 2 }} variant="caption">
                  Lengkapi data diri untuk menambahkan kost anda
                </Typography>
                <Button
                  onClick={userLogic.personalData}
                  variant="contained"
                  sx={{ width: "30%" }}
                >
                  Lengkapi data diri
                </Button>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
