import React from "react";
import TextField from "@mui/material/TextField";

export default function GlobalFilter({ filter, setFilter }) {
  return (
    <TextField
      margin="dense"
      label="Cari"
      type="text"
      size="small"
      value={filter || ""}
      onChange={(e) => setFilter(e.target.value)}
      sx={{ mb: 1 }}
    />
  );
}
