import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { showOwner } from "../../../redux/actions/OwnerAction";
import { loadProfileAction } from "../../../redux/actions/UserActions";

const UserLogic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userProfile } = useSelector((state) => state.userDetails);
  const [chartData, setChartData] = useState([]);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: "70px",
        height: "70px",
      },
      children: `${name.split(" ")[0][0]}`,
    };
  }

  const addDorm = () => {
    navigate("add-dorm", { state: { ownerId: userProfile.owner.id } });
  };

  const personalData = () => {
    navigate("personal-data");
  };

  const dormDetail = (dorm) => {
    navigate("dorm-detail", { state: { dormId: dorm.id } });
  };

  useEffect(() => {
    dispatch(loadProfileAction(navigate));
  }, [dispatch, navigate]);

  useEffect(() => {
    let data = [["Kost", "Dilihat"]];
    userProfile?.owner?.dorms?.map((dorm) =>
      data.push([dorm.name, dorm.views_count])
    );
    setChartData(data);
    userProfile?.owner && dispatch(showOwner(userProfile.owner.id));
  }, [userProfile, dispatch]);

  return {
    userProfile,
    chartData,
    stringAvatar,
    addDorm,
    personalData,
    dormDetail,
  };
};

export default UserLogic;
