import { useTheme } from "@emotion/react";
import { styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { LogoutAction } from "../../redux/actions/AuthAction";
import { reloadProfile } from "../../redux/actions/UserActions";

const HeaderLogic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const token = localStorage.getItem("user-token");
  const { userProfile } = useSelector((state) => state.userDetails);

  const settings = ["Dashboard", "Profile", "Logout"];

  const theme = useTheme();
  const drawerWidth = 240;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickUserMenu = (menu) => {
    switch (menu) {
      case "Logout":
        handleCloseMenu();
        return logout();

      case "Profile":
        handleCloseMenu();
        return navigate("/user/personal-data");

      case "Dashboard":
        handleCloseMenu();
        return userProfile?.role === "admin"
          ? navigate("/admin")
          : navigate("/user");

      default:
        return handleCloseMenu();
    }
  };

  const goToLogin = () => {
    navigate("login");
  };

  const goToHome = () => {
    userProfile?.role === "admin" ? navigate("/admin") : navigate("/user");
  };

  const logout = () => {
    dispatch(LogoutAction(navigate));
  };

  const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    })
  );

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: "40px",
        height: "40px",
      },
      children: `${name.split(" ")[0][0]}`,
    };
  }

  const goToDorms = () => {
    navigate("admin/dorms");
    handleDrawerClose();
  };
  const goToFacilities = () => {
    navigate("admin/facilities");
    handleDrawerClose();
  };
  const goToOwners = () => {
    navigate("admin/owners");
    handleDrawerClose();
  };
  const goToOwnerVerification = () => {
    navigate("admin/owner-verification");
    handleDrawerClose();
  };
  const goToCategories = () => {
    navigate("admin/categories");
    handleDrawerClose();
  };
  const goToDeposits = () => {
    navigate("admin/deposits");
    handleDrawerClose();
  };
  const goToRecomendations = () => {
    navigate("admin/recomendations");
    handleDrawerClose();
  };

  useEffect(() => {
    if (token) {
      !userProfile && dispatch(reloadProfile());
    }
    return () => {};
  }, [token, userProfile, dispatch]);

  return {
    pathname,
    settings,
    theme,
    drawerWidth,
    DrawerHeader,
    open,
    Main,
    token,
    userProfile,
    anchorEl,
    openMenu,
    goToLogin,
    goToHome,
    goToDorms,
    goToFacilities,
    goToOwners,
    goToOwnerVerification,
    goToCategories,
    goToDeposits,
    goToRecomendations,
    handleClickUserMenu,
    handleDrawerOpen,
    handleDrawerClose,
    stringAvatar,
    handleClickMenu,
    handleCloseMenu,
  };
};

export default HeaderLogic;
