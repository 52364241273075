import {
  CLEAR_DORM_DATA,
  LOAD_ALL_DORM_ERROR,
  LOAD_ALL_DORM_SUCCESS,
  SET_DORM_DETAIL_SUCCESS,
  SHOW_DORM_SUCCESS,
} from "../ActionTypes";

const initialState = {
  dormList: null,
  detail: null,
};

const DormReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_ALL_DORM_SUCCESS:
      return {
        ...state,
        dormList: payload.data,
      };

    case LOAD_ALL_DORM_ERROR:
      return {
        ...state,
        dormList: null,
      };

    case SHOW_DORM_SUCCESS:
      return {
        ...state,
        detail: payload.data,
      };

    case SET_DORM_DETAIL_SUCCESS:
      return {
        ...state,
        detail: payload,
      };

    case CLEAR_DORM_DATA:
      return {
        dormList: null,
        detail: null,
      };

    default:
      return state;
  }
};

export default DormReducer;
