import { useEffect, useState } from "react";
import depositService from "../../../services/DepositService";
import { useDispatch } from "react-redux";
import {
  CODE_ERROR,
  LOADING,
  USERS_DEPOSIT_LOADED,
} from "../../../redux/ActionTypes";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

const DepositsLogic = () => {
  const [depositsList, setDepositsList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    {
      field: "name",
      headerName: "Nama",
      width: 300,
      valueGetter: (params) => {
        return params.row.user.name;
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      valueGetter: (params) => {
        return params.row.user.email;
      },
    },
    {
      field: "total_deposits",
      headerName: "Deposit saat ini",
      width: 300,
      valueGetter: (params) => {
        return params.row.remaining + " Poin";
      },
    },
    {
      field: "action",
      headerName: "Aksi",
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => depositsDetail(params.row.id)}>Detail</Button>
      ),
    },
  ];

  const depositsDetail = (id) => {
    navigate("/admin/deposits-detail/" + id);
  };

  useEffect(() => {
    dispatch({ type: LOADING });
    depositService.getAllDeposits(
      (res) => {
        setDepositsList(res.data);
        dispatch({ type: USERS_DEPOSIT_LOADED });
      },
      () => dispatch({ type: CODE_ERROR })
    );

    return () => {};
  }, [dispatch]);

  return {
    depositsList,
    columns,
    depositsDetail,
  };
};

export default DepositsLogic;
