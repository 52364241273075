import {
  CLEAR_OWNER_DATA,
  LOAD_ALL_OWNERS_SUCCESS,
  SHOW_OWNER_SUCCESS,
} from "../ActionTypes";

const initialState = {
  ownerList: null,
  profile: null,
};

const OwnerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_ALL_OWNERS_SUCCESS:
      return { ...state, ownerList: payload.data };

    case CLEAR_OWNER_DATA:
      return { ownerList: null, profile: null };

    case SHOW_OWNER_SUCCESS:
      return { ...state, profile: payload.data };

    default:
      return state;
  }
};

export default OwnerReducer;
