import depositService from "../../services/DepositService";
import {
  CLEAR_DEPOSITS_DATA,
  CODE_ERROR,
  DELETE_DEPOSIT_SUCCESS,
  LOADING,
  SET_DEPOSITS_DETAIL_SUCCESS,
  STORE_DEPOSIT_SUCCESS,
} from "../ActionTypes";

export const setDepositsDetail = (id) => (dispatch) => {
  dispatch({ type: LOADING });
  depositService.showDeposits(
    id,
    (res) =>
      dispatch({
        type: SET_DEPOSITS_DETAIL_SUCCESS,
        payload: res.data,
      }),
    () => dispatch({ type: CODE_ERROR })
  );
};

export const storeDeposit = (owner_id, deposits) => (dispatch) => {
  dispatch({ type: LOADING });
  depositService.storeDeposit(
    { owner_id, deposits },
    () => {
      dispatch({ type: STORE_DEPOSIT_SUCCESS });
      depositService.showDeposits(
        owner_id,
        (res) => {
          dispatch({
            type: SET_DEPOSITS_DETAIL_SUCCESS,
            payload: res.data,
          });
        },
        () => dispatch({ type: CODE_ERROR })
      );
    },
    () => dispatch({ type: CODE_ERROR })
  );
};

export const deleteDeposit = (depositId) => (dispatch) => {
  dispatch({ type: LOADING });
  depositService.deleteDeposit(
    depositId,
    (deleteResponse) => {
      dispatch({ type: DELETE_DEPOSIT_SUCCESS });
      depositService.showDeposits(
        deleteResponse.data.owner_id,
        (showResponse) => {
          dispatch({
            type: SET_DEPOSITS_DETAIL_SUCCESS,
            payload: showResponse.data,
          });
        },
        () => dispatch({ type: CODE_ERROR })
      );
    },
    () => dispatch({ type: CODE_ERROR })
  );
};

export const clearDepositsData = () => ({
  type: CLEAR_DEPOSITS_DATA,
});
