import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import GradeIcon from "@mui/icons-material/Grade";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Avatar,
  ButtonBase,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import HeaderLogic from "./HeaderLogic";
import HouseIcon from "@mui/icons-material/House";
import BedIcon from "@mui/icons-material/Bed";
import GroupIcon from "@mui/icons-material/Group";
import PaidIcon from "@mui/icons-material/Paid";
import ClassIcon from "@mui/icons-material/Class";
import Logo from "../../assets/images/Logo.png";

export default function Header() {
  const headerLogic = HeaderLogic();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar color="primary" position="static">
        <Toolbar>
          {headerLogic.userProfile?.role === "admin" && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={headerLogic.handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box component="div" sx={{ flexGrow: 1 }}>
            <ButtonBase
              disabled={headerLogic.pathname === "/"}
              onClick={headerLogic.goToHome}
            >
              <Box
                component="img"
                sx={{
                  height: 50,
                  width: 60,
                }}
                alt="Logo"
                src={Logo}
              />
              <Typography variant="h6">Amarfa Kost</Typography>
            </ButtonBase>
          </Box>
          <div>
            {headerLogic.userProfile?.name && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton
                    onClick={headerLogic.handleClickMenu}
                    sx={{ p: 0 }}
                  >
                    <Avatar
                      {...headerLogic.stringAvatar(
                        headerLogic.userProfile.name
                      )}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="basic-menu"
                  anchorEl={headerLogic.anchorEl}
                  open={headerLogic.openMenu}
                  onClose={headerLogic.handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {headerLogic.settings.map((setting, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => headerLogic.handleClickUserMenu(setting)}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: headerLogic.drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: headerLogic.drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={headerLogic.open}
      >
        <headerLogic.DrawerHeader>
          <IconButton onClick={headerLogic.handleDrawerClose}>
            {headerLogic.theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </headerLogic.DrawerHeader>
        <Divider />
        <List>
          <ListItem button onClick={headerLogic.goToDorms}>
            <ListItemIcon>
              <HouseIcon />
            </ListItemIcon>
            <ListItemText primary="Data Kost" />
          </ListItem>
          <ListItem button onClick={headerLogic.goToFacilities}>
            <ListItemIcon>
              <BedIcon />
            </ListItemIcon>
            <ListItemText primary="Fasilitas" />
          </ListItem>
          <ListItem button onClick={headerLogic.goToCategories}>
            <ListItemIcon>
              <ClassIcon />
            </ListItemIcon>
            <ListItemText primary="Kategori" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={headerLogic.goToOwners}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Pemilik" />
          </ListItem>
          <ListItem button onClick={headerLogic.goToOwnerVerification}>
            <ListItemIcon>
              <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Verifikasi Pemilik" />
          </ListItem>
          <ListItem button onClick={headerLogic.goToDeposits}>
            <ListItemIcon>
              <PaidIcon />
            </ListItemIcon>
            <ListItemText primary="Deposit" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={headerLogic.goToRecomendations}>
            <ListItemIcon>
              <GradeIcon />
            </ListItemIcon>
            <ListItemText primary="Rekomendasi" />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
