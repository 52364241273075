import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./components/AppBar/Header";
import PrivateOutlet from "./PrivateOutlet";
import SignIn from "./pages/Login/Login";
import User from "./pages/User/User/User";
import Register from "./pages/Register/Register";
import Loader from "./components/Loader/Loader";
import EmailVerification from "./pages/User/EmailVerification/EmailVerification";
import AdminOutlet from "./AdminOutlet";
import Admin from "./pages/Admin/Admin/Admin";
import AddDorm from "./pages/AddDorm/AddDorm";
import PersonalData from "./pages/User/PersonalData/PersonalData";
import Dorms from "./pages/Admin/Dorms/Dorms";
import Facilities from "./pages/Admin/Facilities/Facilities";
import Owners from "./pages/Admin/Owners/Owners";
import OwnerDetail from "./pages/Admin/OwnerDetail/OwnerDetail";
import DormDetail from "./pages/DormDetail/DormDetail";
import Categories from "./pages/Admin/Categories/Categories";
import EditDorm from "./pages/EditDorm/EditDorm";
import Deposits from "./pages/Admin/Deposits/Deposits";
import DepositsDetail from "./pages/Admin/DepositsDetail/DepositsDetail";
import Recomendation from "./pages/Admin/Recomendation/Recomendation";
import OwnerVerification from "./pages/Admin/OwnerVerification/OwnerVerification";

function MyRoutes() {
  return (
    <>
      <Header />
      <Loader />
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="register" element={<Register />} />

        {/* Private Route */}
        <Route path="/user" element={<PrivateOutlet />}>
          <Route path="" element={<User />} />
          <Route path="email-verification" element={<EmailVerification />} />
          <Route path="add-dorm" element={<AddDorm />} />
          <Route path="personal-data" element={<PersonalData />} />
          <Route path="dorm-detail" element={<DormDetail />} />
          <Route path="edit-dorm" element={<EditDorm />} />
        </Route>
        <Route path="/admin" element={<AdminOutlet />}>
          <Route path="" element={<Admin />} />
          <Route path="dorms" element={<Dorms />} />
          <Route path="facilities" element={<Facilities />} />
          <Route path="categories" element={<Categories />} />
          <Route path="owners" element={<Owners />} />
          <Route path="owner-detail/:ownerId" element={<OwnerDetail />} />
          <Route path="dorm-detail" element={<DormDetail />} />
          <Route path="deposits" element={<Deposits />} />
          <Route path="deposits-detail/:ownerId" element={<DepositsDetail />} />
          <Route path="add-dorm" element={<AddDorm />} />
          <Route path="edit-dorm" element={<EditDorm />} />
          <Route path="recomendations" element={<Recomendation />} />
          <Route path="owner-verification" element={<OwnerVerification />} />
        </Route>
      </Routes>
    </>
  );
}

export default MyRoutes;
