import api from "./Api";

const storeDormRecomendation = (data) => api.post("dorm-recomendation", data);
const indexRecomendation = () => api.get("dorm-recomendation");
const destroyRecomendation = (id) => api.delete("dorm-recomendation/" + id);

const recomendationService = {
  storeDormRecomendation,
  indexRecomendation,
  destroyRecomendation,
};

export default recomendationService;
