import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { clearDormData, showDorm } from "../../redux/actions/DormAction";
import dormService from "../../services/DormService";

const DormDetailLogic = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { detail } = useSelector((state) => state.dorm);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleOpenDeleteDialog = () => {
    setShowDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
  };
  const handleOpenSnackbar = () => {
    setShowSnackbar(true);
  };
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const back = () => {
    navigate(-1);
  };

  const editDorm = () => {
    navigate("/user/edit-dorm", { state: { dormId: detail.id } });
  };

  const deleteDorm = () => {
    dormService.deleteDorm(detail.id, () => navigate(-1), handleOpenSnackbar);
  };

  useEffect(() => {
    dispatch(showDorm(state.dormId));

    return () => {
      dispatch(clearDormData());
    };
  }, [state, dispatch]);

  return {
    detail,
    showDeleteDialog,
    showSnackbar,
    editDorm,
    handleCloseDeleteDialog,
    handleOpenDeleteDialog,
    deleteDorm,
    handleCloseSnackbar,
    back,
  };
};

export default DormDetailLogic;
