import {
  CLEAR_FACILITY_DATA,
  LOAD_ALL_FACILITIES_SUCCESS,
} from "../ActionTypes";

const initialState = {
  facilityList: [],
};

const FacilityReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_ALL_FACILITIES_SUCCESS:
      return { ...state, facilityList: payload.data };

    case CLEAR_FACILITY_DATA:
      return { facilityList: [] };

    default:
      return state;
  }
};

export default FacilityReducer;
