import {
  CLEAR_CATEGORY_DATA,
  LOAD_ALL_CATEGORIES_SUCCESS,
} from "../ActionTypes";

const initialState = {
  categoryList: [],
};

const CategoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_ALL_CATEGORIES_SUCCESS:
      return { ...state, categoryList: payload.data };

    case CLEAR_CATEGORY_DATA:
      return { categoryList: [] };

    default:
      return state;
  }
};

export default CategoryReducer;
