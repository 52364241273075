import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DataTableLogic from "./DataTableLogic";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import GlobalFilter from "./GlobalFilter";
import { Box } from "@mui/material";

function DataTable({ columns, data, actionButtons, hiddenColumns }) {
  const logic = DataTableLogic(columns, data, hiddenColumns);
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FormControl size="small" sx={{ width: 130 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Tampilkan
          </InputLabel>
          <Select
            label="Tampilkan"
            value={logic.pageSize}
            onChange={(e) => logic.setPageSize(Number(e.target.value))}
          >
            {logic.pageSizeList.map((pageSize) => (
              <MenuItem key={pageSize} value={pageSize}>
                {pageSize}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <GlobalFilter
          filter={logic.globalFilter}
          setFilter={logic.setGlobalFilter}
        />
      </Stack>
      {data ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              {logic.headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell {...column.getHeaderProps()}>
                      <b>{column.render("Header")}</b>
                    </TableCell>
                  ))}
                  {actionButtons && (
                    <TableCell>
                      <b>Aksi</b>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {logic.page.map((row) => {
                logic.prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                    {actionButtons && (
                      <TableCell>
                        {actionButtons.map((button) => (
                          <Button
                            key={button.name}
                            variant={button.variant}
                            size="small"
                            onClick={() => button.action(row.values)}
                          >
                            {button.name}
                          </Button>
                        ))}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Box
            sx={{
              m: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              count={logic.pageCount}
              page={logic.pageIndex + 1}
              onChange={(event, value) => logic.gotoPage(value - 1)}
            />
          </Box>
        </TableContainer>
      ) : (
        <></>
      )}
    </>
  );
}

export default DataTable;
