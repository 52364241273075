import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../redux/actions/CategoryAction";
import { storeDorm } from "../../redux/actions/DormAction";
import { getAllFacilities } from "../../redux/actions/FacilityAction";
import {
  clearRegionData,
  setCity,
  setDistrict,
  setProvince,
  setVillage,
} from "../../redux/actions/RegionAction";
import {
  CODE_ERROR,
  LOADING,
  LOAD_PROVINCE_SUCCESS,
} from "../../redux/ActionTypes";
import regionService from "../../services/RegionService";
import { useLocation, useNavigate } from "react-router";
import { showOwner } from "../../redux/actions/OwnerAction";

const AddDormLogic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { province, city, district, village, pinpoint } = useSelector(
    (state) => state.region
  );
  const { profile } = useSelector((state) => state.owner);
  const { facilityList } = useSelector((state) => state.facility);
  const { categoryList } = useSelector((state) => state.category);
  const [name, setName] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [category, setCategory] = useState("");
  const [term, setTerm] = useState("");
  const [selectedImage, setSelectedImage] = useState([]);
  const inputRef = useRef(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [prices, setPrices] = useState([]);
  const [showPriceDialog, setShowPriceDialog] = useState(false);

  const handleOpenPriceDialog = () => setShowPriceDialog(true);
  const handleClosePriceDialog = () => setShowPriceDialog(false);

  const back = () => {
    navigate(-1);
  };

  const savePrices = (data) => {
    setPrices([...prices, data]);
    handleClosePriceDialog();
  };
  const deletePrices = (deletedPrice) => {
    setPrices(prices.filter((price) => price !== deletedPrice));
  };
  const handleOpenSnackbar = () => setShowSnackbar(true);
  const handleCloseSnackbar = () => setShowSnackbar(false);

  const handleUpload = () => inputRef.current?.click();

  const selectProvince = (code) => {
    dispatch({ type: LOADING });
    const selected = provinces.filter((obj) => {
      return obj.code === code;
    });

    dispatch(setProvince(code, selected[0].meta));
    regionService.getCities(
      code,
      (data) => {
        setCities(data);
      },
      (error) => {
        console.log(error);
        dispatch({ type: CODE_ERROR });
      }
    );
  };

  const selectCity = (code) => {
    dispatch({ type: LOADING });
    const selected = cities.filter((obj) => {
      return obj.code === code;
    });

    dispatch(setCity(code, selected[0].meta));
    regionService.getDistricts(
      code,
      (data) => {
        setDistricts(data);
      },
      (error) => {
        console.log(error);
        dispatch({ type: CODE_ERROR });
      }
    );
  };

  const selectDistrict = (code) => {
    dispatch({ type: LOADING });
    const selected = districts.filter((obj) => {
      return obj.code === code;
    });

    dispatch(setDistrict(code, selected[0].meta));
    regionService.getVillages(
      code,
      (data) => {
        setVillages(data);
      },
      (error) => {
        console.log(error);
        dispatch({ type: CODE_ERROR });
      }
    );
  };

  const selectVillage = (code) => {
    const selected = villages.filter((obj) => {
      return obj.code === code;
    });

    dispatch(setVillage(code, selected[0].meta));
  };

  const selectFacility = (facility_id) => {
    setFacilities([...facilities, facility_id]);
  };

  const deselectFacility = (facility_id) => {
    setFacilities(
      facilities.filter(function (facility) {
        return facility !== facility_id;
      })
    );
  };

  // This function will be triggered when the file field change
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage([...selectedImage, ...e.target.files]);
    }
  };

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = (removedImage) => {
    setSelectedImage(
      selectedImage.filter(function (image) {
        return image !== removedImage;
      })
    );
  };

  const submit = () => {
    let phoneNumber = phone.trim();
    if (phoneNumber.startsWith("+62")) {
      phoneNumber = "62" + phoneNumber.slice(3);
    } else if (phoneNumber.startsWith("0")) {
      phoneNumber = "62" + phoneNumber.slice(1);
    }
    const dormData = {
      name: name,
      village_code: village,
      map_point: JSON.stringify(pinpoint),
      category_id: category,
      owner_id: profile.id,
      address: address,
      phone: phoneNumber,
    };
    if (name.trim() === "") {
      setErrorMessage("Nama kost belum diisi");
      handleOpenSnackbar();
    } else if (address.trim() === "") {
      setErrorMessage("Alamat kost belum diisi");
      handleOpenSnackbar();
    } else if (phone.trim() === "") {
      setErrorMessage("No. Hp. belum diisi");
      handleOpenSnackbar();
    } else if (village === "") {
      setErrorMessage("Alamat kost belum diisi");
      handleOpenSnackbar();
    } else if (!pinpoint) {
      setErrorMessage("lokasi pada peta belum dipilih");
      handleOpenSnackbar();
    } else if (category === "") {
      setErrorMessage("Kategori belum dipilih");
      handleOpenSnackbar();
    } else if (prices === []) {
      setErrorMessage("Harga belum diisi");
      handleOpenSnackbar();
    } else if (term.trim() === "") {
      setErrorMessage("Keterangan belum diisi");
      handleOpenSnackbar();
    } else {
      dispatch(
        storeDorm(dormData, facilities, term, selectedImage, prices, navigate)
      );
    }
  };

  useEffect(() => {
    dispatch({ type: LOADING });
    dispatch(showOwner(state.ownerId));
    regionService.getProvinces(
      (data) => {
        setProvinces(data);
        dispatch({ type: LOAD_PROVINCE_SUCCESS });
      },
      (error) => {
        console.log(error);
        dispatch({ type: CODE_ERROR });
      }
    );
    dispatch(getAllFacilities());
    dispatch(getAllCategories());
    return () => {
      dispatch(clearRegionData());
    };
  }, [dispatch, state.ownerId]);

  return {
    name,
    provinces,
    province,
    cities,
    city,
    districts,
    district,
    villages,
    village,
    facilityList,
    categoryList,
    selectedImage,
    inputRef,
    category,
    showSnackbar,
    errorMessage,
    address,
    phone,
    showPriceDialog,
    prices,
    setAddress,
    setPhone,
    setName,
    selectProvince,
    selectCity,
    selectDistrict,
    selectVillage,
    selectFacility,
    deselectFacility,
    setTerm,
    imageChange,
    removeSelectedImage,
    handleUpload,
    setCategory,
    submit,
    handleCloseSnackbar,
    savePrices,
    handleOpenPriceDialog,
    handleClosePriceDialog,
    deletePrices,
    back,
  };
};

export default AddDormLogic;
