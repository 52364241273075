import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router";
import { clearOwnerData, showOwner } from "../../../redux/actions/OwnerAction";

const OwnerDetailLogic = () => {
  const { ownerId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.owner);
  const [chartData, setChartData] = useState([]);

  const dormDetail = (id) => {
    navigate("/admin/dorm-detail", { state: { dormId: id } });
  };

  const addDorm = () => {
    navigate("/admin/add-dorm", { state: { ownerId: profile.id } });
  };

  const back = () => navigate(-1);
  useEffect(() => {
    dispatch(showOwner(ownerId));
    return () => {
      dispatch(clearOwnerData());
    };
  }, [ownerId, dispatch]);

  useEffect(() => {
    let data = [["Kost", "Dilihat"]];
    profile?.dorms?.map((dorm) => data.push([dorm.name, dorm.views_count]));
    setChartData(data);
  }, [profile?.dorms]);

  return { profile, chartData, dormDetail, addDorm, back };
};

export default OwnerDetailLogic;
