import { render } from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import theme from "./theme";
import { Provider } from "react-redux";
import store from "./redux/CreateStore";

const rootElement = document.getElementById("root");
render(
  <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
  </Provider>,
  rootElement
);
