import api from "./Api";

const getAllOwners = (onSuccess, onError) => {
  api
    .get("owner")
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const showOwner = (id, onSuccess, onError) => {
  api
    .get("owner/" + id)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const addOwner = (data) => api.post("add-user-owner", data);

const ownerService = {
  getAllOwners,
  showOwner,
  addOwner,
};

export default ownerService;
