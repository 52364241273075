import * as ActionTypes from "../ActionTypes";

const initState = {
  authResponse: "",
};

const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.RESTART_AUTH_RESPONSE:
      return {
        ...state,
        authResponse: "",
      };
    case ActionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        authResponse: action.res,
      };
    case ActionTypes.SIGNUP_ERROR:
      return {
        ...state,
        authResponse: action.res,
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        authResponse: "redirecting to dashboard...",
      };
    case ActionTypes.LOGIN_ERROR:
      return {
        ...state,
        authResponse: action.res,
      };
    case ActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        authResponse: action.res,
      };
    case ActionTypes.LOGOUT_ERROR:
      return {
        ...state,
        authResponse: action.res,
      };
    case ActionTypes.EMAIL_NOT_VERIFIED:
      return {
        ...state,
        authResponse: "email not verified",
      };
    default:
      return state;
  }
};
export default AuthReducer;
