import { Container, CssBaseline, Paper, Typography } from "@mui/material";
import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import OwnerVerificationLogic from "./OwnerVerificationLogic";
import Modal from "@mui/material/Modal";
import DeleteDialog from "../../../components/DeleteDialog/DeleteDialog";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import FormDialog from "../../../components/FormDialog/FormDialog";

export default function OwnerVerification() {
  const logic = OwnerVerificationLogic();
  return (
    <Container>
      <CssBaseline />
      <Paper sx={{ mt: 5, p: 2 }}>
        <Typography variant="h5" marginY={3} textAlign="center">
          Verifikasi
        </Typography>
        {logic.ownerVerifications && (
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid rows={logic.ownerVerifications} columns={logic.columns} />
          </div>
        )}
        <Typography variant="h5" marginY={3} textAlign="center">
          Riwayat
        </Typography>
        {logic.verificationHistory && (
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={logic.verificationHistory}
              columns={logic.historyColumns}
            />
          </div>
        )}
      </Paper>
      <Modal
        open={logic.open}
        onClose={logic.handleClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logic.selectedImage} alt={"id-card"} />
      </Modal>

      <ConfirmDialog
        title="Accept"
        text="Apakah anda yakin untuk verifikasi data diri ini"
        show={logic.showAcceptDialog}
        close={logic.handleCloseAcceptDialog}
        confirm={logic.accept}
      />
      <FormDialog
        show={logic.showRejectDialog}
        dialogTitle="Reject"
        dialogContentText="Tolak data diri"
        fields={logic.rejectFields}
        handleFieldChange={logic.handleRejectDescriptionChange}
        close={logic.handleCloseRejectDialog}
        submit={logic.reject}
      />
      <DeleteDialog
        title="Hapus"
        text="Data"
        show={logic.showDeleteDialog}
        close={logic.handleCloseDeleteDialog}
        confirm={logic.deleteVerification}
      />
    </Container>
  );
}
