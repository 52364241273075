const getLocalAccessToken = () => {
  const token = localStorage.getItem("user-token");
  return token;
};

const updateLocalAccessToken = (token) => {
  let auth = localStorage.getItem("user-token");
  localStorage.setItem("user-token", auth);
};

const TokenService = {
  getLocalAccessToken,
  updateLocalAccessToken,
};

export default TokenService;
