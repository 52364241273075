import ownerService from "../../services/OwnerService";
import {
  CLEAR_OWNER_DATA,
  CODE_ERROR,
  LOADING,
  LOAD_ALL_OWNERS_SUCCESS,
  SHOW_OWNER_SUCCESS,
} from "../ActionTypes";

export const getAllOwners = () => (dispatch) => {
  dispatch({ type: LOADING });
  ownerService.getAllOwners(
    (res) => {
      dispatch({
        type: LOAD_ALL_OWNERS_SUCCESS,
        payload: res,
      });
    },
    () =>
      dispatch({
        type: CODE_ERROR,
      })
  );
};

export const showOwner = (id) => (dispatch) => {
  dispatch({ type: LOADING });
  ownerService.showOwner(
    id,
    (res) =>
      dispatch({
        type: SHOW_OWNER_SUCCESS,
        payload: res,
      }),
    () =>
      dispatch({
        type: CODE_ERROR,
      })
  );
};

export const clearOwnerData = () => ({
  type: CLEAR_OWNER_DATA,
});
