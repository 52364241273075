import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  loadProfileAction,
  SubmitPersonalData,
} from "../../../redux/actions/UserActions";
import userService from "../../../services/UserService";

export const PersonalDataLogic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phone, setPhone] = useState("");
  const { userProfile } = useSelector((state) => state.userDetails);
  const message = useSelector((state) => state.message);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [profile, setProfile] = useState({
    name: userProfile?.name,
    phone: userProfile?.owner?.phone,
  });
  const [password, setPassword] = useState({
    current_password: "",
    new_password: "",
    new_confirm_password: "",
  });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [alert, setAlert] = useState({
    severity: "error",
    message: "",
  });

  const handleShowEditProfile = () => {
    setShowEditProfile(true);
  };
  const handleCloseEditProfile = () => {
    setShowEditProfile(false);
  };
  const handleShowChangePassword = () => {
    setShowChangePassword(true);
  };
  const handleCloseChangePassword = () => {
    setShowChangePassword(false);
  };

  const editProfileFields = [
    {
      id: "name",
      name: "Nama",
      type: "Text",
      value: profile.name,
    },
    {
      id: "phone",
      name: "No. Hp/Whatsapp",
      type: "Text",
      value: profile.phone,
    },
  ];

  const changePasswordFields = [
    {
      id: "current_password",
      name: "Password Lama",
      type: "password",
    },
    {
      id: "new_password",
      name: "Password Baru",
      type: "password",
    },
    {
      id: "new_confirm_password",
      name: "Ulangi Password Baru",
      type: "password",
    },
  ];

  const handleProfileChange = (e) => {
    setProfile({
      ...profile,
      [e.target.id]: e.target.value,
    });
  };

  const handlePasswordChange = (e) => {
    setPassword({
      ...password,
      [e.target.id]: e.target.value,
    });
  };

  const handleFieldChange = (e) => {
    setPhone(e.target.value);
  };

  const submitUpdateProfile = () => {
    let phoneNumber = profile.phone.trim();
    if (phoneNumber.startsWith("+62")) {
      phoneNumber = "62" + phoneNumber.slice(3);
    } else if (phoneNumber.startsWith("0")) {
      phoneNumber = "62" + phoneNumber.slice(1);
    }

    userService
      .updateUser({
        name: profile.name,
        phone: phoneNumber.replace(/[- .]/g, ""),
      })
      .then(() => {
        handleCloseEditProfile();
        setAlert({
          severity: "success",
          message: "Profil berhasil diubah",
        });
        handleOpenSnackbar();
        dispatch(loadProfileAction(navigate));
      })
      .catch(() => {
        setAlert({
          severity: "error",
          message: "Terjadi kesalahan, gagal menubah profil",
        });
        handleOpenSnackbar();
      });
  };

  const submitNewPassword = () => {
    userService
      .submitNewPassword(password)
      .then(() => {
        handleCloseChangePassword();
        setAlert({
          severity: "success",
          message: "Password berhasil diubah",
        });
        handleOpenSnackbar();
      })
      .catch((err) => {
        let errors = Object.values(err.response.data.message);
        setAlert({
          severity: "error",
          message: errors[0],
        });
        handleOpenSnackbar();
      });
  };

  const submitData = () => {
    let phoneNumber = phone.trim();
    if (phoneNumber.startsWith("+62")) {
      phoneNumber = "62" + phoneNumber.slice(3);
    } else if (phoneNumber.startsWith("0")) {
      phoneNumber = "62" + phoneNumber.slice(1);
    }
    const data = {
      user_id: userProfile.id,
      phone: phoneNumber.replace(/[- .]/g, ""),
    };
    dispatch(SubmitPersonalData(data, navigate));
  };

  const handleOpenSnackbar = () => {
    setShowSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return {
    phone,
    userProfile,
    message,
    showEditProfile,
    showChangePassword,
    editProfileFields,
    changePasswordFields,
    showSnackbar,
    alert,
    handleFieldChange,
    submitData,
    handleCloseEditProfile,
    handleCloseChangePassword,
    handleShowEditProfile,
    handleShowChangePassword,
    handleProfileChange,
    handlePasswordChange,
    submitUpdateProfile,
    submitNewPassword,
    handleCloseSnackbar,
  };
};

export default PersonalDataLogic;
