import api from "./Api";

const storeTerm = (dorm_id, term, onSuccess, onError) => {
  api
    .post("term", { dorm_id, term })
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const showTerm = (dormId, onSuccess, onError) => {
  api
    .get("term/" + dormId)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const updateTerm = (dormId, term, onSuccess, onError) => {
  api
    .put("term/" + dormId, { term })
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const termService = {
  storeTerm,
  showTerm,
  updateTerm,
};

export default termService;
