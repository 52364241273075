import * as ActionTypes from "../ActionTypes";

const initState = {
  userProfile: "",
};

const UserReducer = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.res.data,
      };
    case ActionTypes.LOAD_PROFILE_ERROR:
      return {
        ...state,
        userProfile: null,
      };

    case ActionTypes.CLEAR_USER_DATA:
      return { userProfile: null };

    default:
      return state;
  }
};
export default UserReducer;
