import React from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";

const DataTableLogic = (tableColumns, tableData, hiddenColumns = []) => {
  const columns = React.useMemo(() => tableColumns, [tableColumns]);
  const data = React.useMemo(() => (tableData ? tableData : []), [tableData]);
  const pageSizeList = [10, 25, 50];

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    state,
    setGlobalFilter,
    gotoPage,
    setPageSize,
    pageCount,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: hiddenColumns,
      },
    },
    useGlobalFilter,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return {
    headerGroups,
    page,
    globalFilter,
    setGlobalFilter,
    gotoPage,
    pageIndex,
    pageSizeList,
    pageSize,
    setPageSize,
    pageCount,
    getTableProps,
    getTableBodyProps,
    prepareRow,
  };
};

export default DataTableLogic;
