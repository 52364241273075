import categoryService from "../../services/CategoryService";
import {
  ADD_NEW_CATEGORY_SUCCESS,
  CLEAR_CATEGORY_DATA,
  CODE_ERROR,
  DELETE_CATEGORY_SUCCESS,
  LOADING,
  LOAD_ALL_CATEGORIES_SUCCESS,
} from "../ActionTypes";

export const getAllCategories = () => (dispatch) => {
  dispatch({ type: LOADING });
  categoryService.getAllCategories((res) => {
    dispatch({
      type: LOAD_ALL_CATEGORIES_SUCCESS,
      payload: res,
    });
  });
};

export const addNewCategory = (category, onSuccess) => (dispatch) => {
  dispatch({ type: LOADING });
  categoryService.addNewCategory(
    category,
    () => {
      dispatch({
        type: ADD_NEW_CATEGORY_SUCCESS,
      });
      onSuccess();
    },
    () =>
      dispatch({
        type: CODE_ERROR,
      })
  );
};

export const deleteCategory = (id, onSuccess) => (dispatch) => {
  dispatch({ type: LOADING });
  categoryService.deleteCategory(
    id,
    () => {
      dispatch({
        type: DELETE_CATEGORY_SUCCESS,
      });
      onSuccess();
    },
    () =>
      dispatch({
        type: CODE_ERROR,
      })
  );
};

export const clearCategoryData = () => ({
  type: CLEAR_CATEGORY_DATA,
});
