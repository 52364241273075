import {
  Button,
  Container,
  CssBaseline,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import React from "react";
import OwnersLogic from "./OwnersLogic";
import { DataGrid } from "@mui/x-data-grid";
import FormDialog from "../../../components/FormDialog/FormDialog";

export default function Owners() {
  const logic = OwnersLogic();
  return (
    <Container>
      <CssBaseline />
      <Paper sx={{ mt: 5, p: 2 }}>
        <Button
          variant="contained"
          sx={{ marginY: 3 }}
          onClick={logic.handleOpenAddOwnerDialog}
        >
          Tambah Pemilik
        </Button>
        {logic.ownerList && (
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={logic.ownerList}
              columns={logic.columns}
              checkboxSelection
            />
          </div>
        )}
      </Paper>
      <FormDialog
        show={logic.showAddOwnerDialog}
        dialogTitle="Tambah Pemilik"
        dialogContentText="Tambah Pemilik Baru"
        fields={logic.addOwnerFields}
        handleFieldChange={logic.handleAddOwnerChange}
        close={logic.handleCloseAddOwnerDialog}
        submit={logic.submitAddOwner}
      />
      <Snackbar
        open={logic.showSnackbar}
        autoHideDuration={6000}
        onClose={logic.handleCloseSnackbar}
      >
        <Alert
          onClose={logic.handleCloseSnackbar}
          severity={logic.alert.severity}
          sx={{ width: "100%" }}
        >
          {logic.alert.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}
