import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import AddDormLogic from "./AddDormLogic";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import PriceDialog from "../../components/PriceDialog/PriceDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import LeafletMap from "../../components/LeafletMap/LeafletMap";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

export default function AddDorm() {
  const logic = AddDormLogic();

  return (
    <Container>
      <CssBaseline />
      <Grid container spacing={1} sx={{ mt: 3 }}>
        <Grid item sm={4}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ borderRadius: 5, m: 2 }}
            onClick={logic.back}
          >
            <KeyboardBackspaceIcon sx={{ marginRight: 2 }} />
            Kembali
          </Button>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Tambah Kost/Kontrakan Baru
            </Typography>
            <FormControl>
              <TextField
                label="Nama Kost"
                variant="standard"
                onChange={(e) => logic.setName(e.target.value)}
              />
              <TextField
                sx={{ mt: 2 }}
                label="Alamat Lengkap"
                multiline
                onChange={(e) => logic.setAddress(e.target.value)}
              />
              <TextField
                sx={{ my: 2 }}
                size="small"
                label="No. Hp (Whatsapp)"
                onChange={(e) => logic.setPhone(e.target.value)}
              />
            </FormControl>
            <Paper
              elevation={3}
              sx={{ mt: 1, p: 1, display: "flex", flexDirection: "column" }}
            >
              <Typography variant="body2" sx={{ mb: 2 }}>
                Fasilitas:
              </Typography>
              <Grid container>
                {logic.facilityList?.map((facility) => (
                  <FormControlLabel
                    key={facility.id}
                    control={<Checkbox />}
                    label={facility.facility}
                    onChange={(e) => {
                      if (e.target.checked) {
                        logic.selectFacility(facility.id);
                      } else {
                        logic.deselectFacility(facility.id);
                      }
                    }}
                  />
                ))}
              </Grid>
            </Paper>
            <Paper
              elevation={3}
              sx={{ mt: 1, p: 1, display: "flex", flexDirection: "column" }}
            >
              <Typography variant="body2" sx={{ mb: 2 }}>
                Keterangan:
              </Typography>
              <TextField
                label="Keterangan"
                placeholder="Keterangan"
                multiline
                onChange={(e) => logic.setTerm(e.target.value)}
              />
            </Paper>
            <Paper
              elevation={3}
              sx={{ mt: 1, p: 1, display: "flex", flexDirection: "column" }}
            >
              <Typography variant="body2" sx={{ mb: 2 }}>
                Kategori:
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={logic.category}
                onChange={(e) => logic.setCategory(e.target.value)}
              >
                <Grid container>
                  {logic.categoryList?.map((category) => (
                    <FormControlLabel
                      key={category.id}
                      value={category.id}
                      control={<Radio />}
                      label={category.category}
                    />
                  ))}
                </Grid>
              </RadioGroup>
            </Paper>
            <Paper
              elevation={3}
              sx={{ mt: 1, p: 1, display: "flex", flexDirection: "column" }}
            >
              <Typography variant="body2" sx={{ mb: 2 }}>
                Harga:
              </Typography>
              {logic.prices.map((price, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body2">
                    {price.max_price
                      ? new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(price.price) +
                        " - " +
                        new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(price.max_price)
                      : new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(price.price)}
                    /{price.unit}
                  </Typography>
                  <IconButton onClick={() => logic.deletePrices(price)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Box>
                <Button
                  variant="contained"
                  onClick={logic.handleOpenPriceDialog}
                >
                  Tambahkan Harga
                </Button>
              </Box>
            </Paper>
          </Paper>
        </Grid>
        <Grid item sm={8}>
          <Paper
            sx={{
              p: 2,
              height: "470px",
              maxHeight: "63%",
            }}
          >
            <Typography variant="body2">Lokasi:</Typography>
            <Box
              sx={{
                mb: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <FormControl variant="standard" fullWidth sx={{ mr: 1 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Provinsi
                </InputLabel>
                <Select
                  label="Provinsi"
                  value={logic.province}
                  onChange={(e) => logic.selectProvince(e.target.value)}
                >
                  {logic.provinces.map((province) => (
                    <MenuItem key={province.id} value={province.code}>
                      {province.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" fullWidth sx={{ mr: 1 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Kab/Kota
                </InputLabel>
                <Select
                  label="Kab/Kota"
                  value={logic.city}
                  onChange={(e) => logic.selectCity(e.target.value)}
                >
                  {logic.cities.map((city) => (
                    <MenuItem key={city.id} value={city.code}>
                      {city.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" fullWidth sx={{ mr: 1 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Kecamatan
                </InputLabel>
                <Select
                  label="Kecamatan"
                  value={logic.district}
                  onChange={(e) => logic.selectDistrict(e.target.value)}
                >
                  {logic.districts.map((district) => (
                    <MenuItem key={district.id} value={district.code}>
                      {district.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-standard-label">
                  Kelurahan
                </InputLabel>
                <Select
                  label="Kelurahan"
                  value={logic.village}
                  onChange={(e) => logic.selectVillage(e.target.value)}
                >
                  {logic.villages.map((village) => (
                    <MenuItem key={village.id} value={village.code}>
                      {village.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {/* <logic.Map /> */}
            <LeafletMap />
          </Paper>
          <Paper sx={{ p: 2, mt: 2 }}>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Foto:
            </Typography>
            <Grid container>
              {logic.selectedImage.map((item, index) => {
                return (
                  <Box
                    key={index}
                    marginRight={1}
                    sx={{
                      my: 1,
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img src={URL.createObjectURL(item)} alt="" />
                    <Box>
                      <Chip variant="outlined" size="small" label={item.name} />
                      <IconButton
                        onClick={() => logic.removeSelectedImage(item)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}
            </Grid>
            <input
              ref={logic.inputRef}
              accept="image/*"
              type="file"
              onChange={logic.imageChange}
              hidden
              multiple
            />
            <IconButton
              onClick={logic.handleUpload}
              sx={{ m: 2, p: 2, border: 1 }}
            >
              <AddPhotoAlternateIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item sm={12} sx={{ my: 2 }}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              sx={{ alignSelf: "center" }}
              onClick={logic.submit}
            >
              Tambahkan Kost
            </Button>
          </Paper>
        </Grid>
      </Grid>
      <PriceDialog
        show={logic.showPriceDialog}
        close={logic.handleClosePriceDialog}
        save={logic.savePrices}
      />
      <Snackbar
        open={logic.showSnackbar}
        autoHideDuration={6000}
        onClose={logic.handleCloseSnackbar}
      >
        <Alert
          onClose={logic.handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {logic.errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
