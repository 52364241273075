import {
  CLEAR_REGION_DATA,
  MAP_MARKED,
  SET_CITY,
  SET_DISTRICT,
  SET_PROVINCE,
  SET_VILLAGE,
} from "../ActionTypes";

const initState = {
  province: "",
  city: "",
  district: "",
  village: "",
  meta: "",
  pinpoint: null,
};

const RegionReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PROVINCE:
      return {
        ...state,
        province: payload.code,
        meta: payload.meta,
      };

    case SET_CITY:
      const city = JSON.parse(payload.meta);
      if (city.lat === "NULL" || city.long === "NULL") {
        return {
          ...state,
          city: payload.code,
        };
      } else {
        return {
          ...state,
          city: payload.code,
          meta: payload.meta,
        };
      }
    case SET_DISTRICT:
      const district = JSON.parse(payload.meta);
      if (district.lat === "NULL" || district.long === "NULL") {
        return {
          ...state,
          district: payload.code,
        };
      } else {
        return {
          ...state,
          district: payload.code,
          meta: payload.meta,
        };
      }

    case SET_VILLAGE:
      const village = JSON.parse(payload.meta);
      if (village.lat === "NULL" || village.long === "NULL") {
        return {
          ...state,
          village: payload.code,
        };
      } else {
        return {
          ...state,
          village: payload.code,
          meta: payload.meta,
        };
      }

    case CLEAR_REGION_DATA:
      return {
        province: "",
        city: "",
        district: "",
        village: "",
        meta: "",
        pinpoint: null,
      };

    case MAP_MARKED:
      return {
        ...state,
        pinpoint: payload,
      };

    default:
      return state;
  }
};

export default RegionReducer;
