import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  clearOwnerData,
  getAllOwners,
} from "../../../redux/actions/OwnerAction";
import ownerService from "../../../services/OwnerService";

const OwnersLogic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ownerList } = useSelector((state) => state.owner);
  const [showAddOwnerDialog, setShowAddOwnerDialog] = useState(false);
  const [newOwner, setNewOwner] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [alert, setAlert] = useState({
    severity: "error",
    message: "",
  });

  const handleOpenAddOwnerDialog = () => setShowAddOwnerDialog(true);
  const handleCloseAddOwnerDialog = () => setShowAddOwnerDialog(false);
  const handleOpenSnackbar = () => setShowSnackbar(true);
  const handleCloseSnackbar = () => setShowSnackbar(false);

  const addOwnerFields = [
    {
      id: "name",
      name: "Nama",
      type: "Text",
    },
    {
      id: "email",
      name: "Email",
      type: "Text",
    },
    {
      id: "phone",
      name: "No. Hp/Whatsapp",
      type: "Text",
    },
  ];

  const handleAddOwnerChange = (e) => {
    setNewOwner({
      ...newOwner,
      [e.target.id]: e.target.value,
    });
  };

  const submitAddOwner = () => {
    let phoneNumber = newOwner.phone.trim();
    if (phoneNumber.startsWith("+62")) {
      phoneNumber = "62" + phoneNumber.slice(3);
    } else if (phoneNumber.startsWith("0")) {
      phoneNumber = "62" + phoneNumber.slice(1);
    }
    const data = {
      name: newOwner.name,
      email: newOwner.email,
      password: "amarfakost",
      password_confirmation: "amarfakost",
      role: "mitra",
      phone: phoneNumber,
    };
    ownerService
      .addOwner(data)
      .then(() => {
        handleCloseAddOwnerDialog();
        dispatch(getAllOwners());
        setAlert({
          severity: "success",
          message: "Pemilik berhasil ditambahkan",
        });
        handleOpenSnackbar();
      })
      .catch(() => {
        setAlert({
          severity: "error",
          message: "Terjadi kesalahan, gagal menambahkan pemilik",
        });
        handleOpenSnackbar();
      });
  };

  const columns = [
    {
      field: "name",
      headerName: "Nama",
      width: 300,
      valueGetter: (params) => {
        return params.row.user.name;
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      valueGetter: (params) => {
        return params.row.user.email;
      },
    },
    { field: "phone", headerName: "Nomor HP", width: 200 },
    {
      field: "action",
      headerName: "Aksi",
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => navigate("/admin/owner-detail/" + params.row.id)}
        >
          Lihat
        </Button>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getAllOwners());

    return () => {
      dispatch(clearOwnerData());
    };
  }, [dispatch]);

  return {
    ownerList,
    columns,
    showAddOwnerDialog,
    addOwnerFields,
    alert,
    showSnackbar,
    handleOpenAddOwnerDialog,
    handleCloseAddOwnerDialog,
    handleAddOwnerChange,
    handleCloseSnackbar,
    submitAddOwner,
  };
};

export default OwnersLogic;
