import {
  CLEAR_DEPOSITS_DATA,
  SET_DEPOSITS_DETAIL_SUCCESS,
} from "../ActionTypes";

const initialState = {
  detail: null,
};

const DepositReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DEPOSITS_DETAIL_SUCCESS:
      return { ...state, detail: payload };

    case CLEAR_DEPOSITS_DATA:
      return { detail: null };

    default:
      return state;
  }
};

export default DepositReducer;
