import api from "./Api";

const loadProfile = (onSuccess, onError) => {
  api
    .get("users/view-profile")
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const submitOwnerData = (data, onSuccess, onError) => {
  api
    .post("owner", data)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const submitNewPassword = (data) => api.post("change-password", data);
const updateUser = (data) => api.put("user", data);

const userService = {
  loadProfile,
  submitOwnerData,
  submitNewPassword,
  updateUser,
};

export default userService;
