import React from "react";
import { Container, CssBaseline, Paper } from "@mui/material";
import DepositsLogic from "./DepositsLogic";
import { DataGrid } from "@mui/x-data-grid";

export default function Deposits() {
  const logic = DepositsLogic();
  return (
    <Container>
      <CssBaseline />
      <Paper sx={{ mt: 5, p: 2 }}>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={logic.depositsList}
            columns={logic.columns}
            checkboxSelection
          />
        </div>
      </Paper>
    </Container>
  );
}
