import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import viewService from "../../../services/ViewService";
import { useNavigate } from "react-router";
import { loadProfileAction } from "../../../redux/actions/UserActions";

const AdminLogic = () => {
  const { userProfile } = useSelector((state) => state.userDetails);
  const [chartData, setChartData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let data = [["Tanggal", "Dilihat"]];
    viewService.getViewsWeek().then((response) => {
      response.data.data.map((item) => data.push([item.date, parseInt(item.views)]));
      setChartData(data);
    });

    return () => {};
  }, []);

  useEffect(() => {
    dispatch(loadProfileAction(navigate));
  }, [dispatch, navigate]);

  return {
    userProfile,
    chartData,
  };
};

export default AdminLogic;
