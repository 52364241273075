import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import EmailVerificationLogic from "./EmailVerificationLogic";
export default function EmailVerification() {
  const logic = EmailVerificationLogic();
  return (
    <Container>
      <CssBaseline />
      <Card sx={{ mt: 5 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Verifikasi Email
          </Typography>
          <Typography variant="body2">
            Silakan verifikasi akun melalui email yang telah dikirim ke alamat
            email <b>{logic.authResponse?.email}</b>
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="contained" onClick={logic.resend}>
            Kirim Ulang
          </Button>
        </CardActions>
      </Card>
      <Dialog open={logic.showDialog} onClose={logic.closeDialog} fullWidth>
        <DialogTitle>Email Verifikasi</DialogTitle>
        <DialogContent>
          <DialogContentText>{logic.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logic.closeDialog}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
