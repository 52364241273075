import dormService from "../../services/DormService";
import facilityService from "../../services/FacilityService";
import photoService from "../../services/PhotoService";
import termService from "../../services/TermService";
import {
  CLEAR_DORM_DATA,
  CODE_ERROR,
  LOADING,
  LOAD_ALL_DORM_ERROR,
  LOAD_ALL_DORM_SUCCESS,
  SET_DORM_DETAIL_SUCCESS,
  SHOW_DORM_SUCCESS,
  UPDATE_DROM_SUCCESS,
  STORE_DORM_SUCCESS,
} from "../ActionTypes";

export const getAllDorm = () => (dispatch) => {
  dispatch({ type: LOADING });
  dormService.getAllDorms(
    (res) => {
      dispatch({
        type: LOAD_ALL_DORM_SUCCESS,
        payload: res,
      });
    },
    (error) => {
      dispatch({
        type: LOAD_ALL_DORM_ERROR,
        payload: error,
      });
    }
  );
};

export const showDorm = (id) => (dispatch) => {
  dispatch({ type: LOADING });
  dormService.showDorm(
    id,
    (res) => {
      dispatch({
        type: SHOW_DORM_SUCCESS,
        payload: res,
      });
    },
    () => {
      dispatch({
        type: CODE_ERROR,
      });
    }
  );
};

export const setDormDetail = (payload) => ({
  type: SET_DORM_DETAIL_SUCCESS,
  payload,
});

export const storeDorm =
  (dormData, facilities, term, selectedImage, prices, navigate) =>
  (dispatch) => {
    dispatch({ type: LOADING });
    dormService.storeDorm(
      dormData,
      selectedImage,
      facilities,
      prices,
      term,
      (res) => {
        dispatch({
          type: STORE_DORM_SUCCESS,
        });
        navigate(-1);
      },
      () =>
        dispatch({
          type: CODE_ERROR,
        })
    );
  };

export const updateDorm =
  (dormId, dormData, facilities, term, selectedImage, navigate) =>
  (dispatch) => {
    dispatch({ type: LOADING });
    dormService.updateDorm(dormId, dormData, (res) => {
      selectedImage.map((image) => {
        const photoForm = new FormData();
        photoForm.append("dorm_id", dormId);
        photoForm.append("photo", image);
        return photoService.storePhoto(
          photoForm,
          () => console.log("photo added"),
          (error) => console.log(error)
        );
      });
      facilityService.removeDormFacilities(
        dormId,
        () => {
          facilities.map((facility) =>
            facilityService.addDormFacility(
              dormId,
              facility,
              () => {},
              (err) => console.log(err)
            )
          );
        },
        () => console.log("error remove facilities")
      );
      termService.updateTerm(
        dormId,
        term,
        () => {
          dispatch({
            type: UPDATE_DROM_SUCCESS,
          });
          navigate(-2);
        },
        (err) => console.log(err)
      );
    });
  };

export const clearDormData = () => ({
  type: CLEAR_DORM_DATA,
});
