import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import RegisterLogic from "./RegisterLogic";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";

function Register() {
  const { handleFieldChange, UserRegister, message, errors } = RegisterLogic();
  return (
    <Container maxWidth="xs" component="main">
      <CssBaseline />
      <Card
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 4,
        }}
      >
        <Typography variant="h5">Daftar</Typography>
        {errors && typeof errors === "object"
          ? errors.map((error, index) => (
              <Alert
                sx={{ width: "100%" }}
                key={index}
                severity={message.status}
              >
                {error}
              </Alert>
            ))
          : message.status && (
              <Alert sx={{ width: "100%" }} severity={message.status}>
                {message.text}
              </Alert>
            )}
        <Box component="form" onSubmit={UserRegister}>
          <TextField
            onChange={handleFieldChange}
            margin="normal"
            required
            fullWidth
            id="name"
            label="Nama"
            name="nama"
            autoFocus
          />
          <TextField
            onChange={handleFieldChange}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Alamat Email"
            name="email"
          />
          <TextField
            onChange={handleFieldChange}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
          />
          <TextField
            onChange={handleFieldChange}
            margin="normal"
            required
            fullWidth
            name="password_confirmation"
            label="Konfirmasi password"
            type="password"
            id="password_confirmation"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<AccountCircle />}
              sx={{ mb: 3 }}
            >
              <b>Daftar</b>
            </Button>
            <Link to="/">Sudah punya akun? Login</Link>
          </Box>
        </Box>
      </Card>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mt: 8, mb: 4 }}
      >
        {"Copyright © "}
        Your Website {new Date().getFullYear()}
        {"."}
      </Typography>
    </Container>
  );
}
export default Register;
