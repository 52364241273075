import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import LoginLogic from "./LoginLogic";
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";

export default function SignIn() {
  const { userLogin, handleFieldChange, message, errors } = LoginLogic();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Card
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 4,
        }}
      >
        <Avatar sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {errors && typeof errors === "object"
          ? errors.map((error, index) => (
              <Alert
                sx={{ width: "100%" }}
                key={index}
                severity={message.status}
              >
                {error}
              </Alert>
            ))
          : message.status && (
              <Alert sx={{ width: "100%" }} severity={message.status}>
                {message.text}
              </Alert>
            )}
        <Box component="form" onSubmit={userLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            onChange={handleFieldChange}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            onChange={handleFieldChange}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
        <Link to="/register">{"Belum punya akun? Daftar"}</Link>
      </Card>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mt: 8, mb: 4 }}
      >
        {"Copyright © "}
        Your Website {new Date().getFullYear()}
        {"."}
      </Typography>
    </Container>
  );
}
