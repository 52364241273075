import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import CategoryReducer from "./CategoryReducer";
import DepositReducer from "./DepositReducer";
import DormReducer from "./DormReducer";
import FacilityReducer from "./FacilityReducer";
import LoadingReducer from "./LoadingReducer";
import MessageReducer from "./MessageReducer";
import OwnerReducer from "./OwnerReducer";
import RegionReducer from "./RegionReducer";
import UserReducer from "./UserReducer";

const RootReducer = combineReducers({
  loading: LoadingReducer,
  userAuth: AuthReducer,
  userDetails: UserReducer,
  message: MessageReducer,
  region: RegionReducer,
  dorm: DormReducer,
  facility: FacilityReducer,
  category: CategoryReducer,
  owner: OwnerReducer,
  deposit: DepositReducer,
});

export default RootReducer;
