import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { LoginAction } from "../../redux/actions/AuthAction";
import { clearMessage } from "../../redux/actions/MessageAction";
import { loadProfileAction } from "../../redux/actions/UserActions";

const LoginLogic = () => {
  const navigate = useNavigate();
  const message = useSelector((state) => state.message);
  const token = localStorage.getItem("user-token");

  const errors =
    typeof message.text === "string"
      ? message.text
      : message.text
      ? Object.values(message.text)
      : null;

  const dispatch = useDispatch();
  //const authResponse = useSelector(state=>state.userAuth.authResponse);
  const [fields, setState] = useState({
    email: "",
    password: "",
  });

  const handleFieldChange = (e) => {
    setState({
      ...fields,
      [e.target.id]: e.target.value,
    });
  };

  const userLogin = (e) => {
    e.preventDefault();
    dispatch(LoginAction(fields, navigate));
  };

  useEffect(() => {
    dispatch(clearMessage());
    token && dispatch(loadProfileAction(navigate));
    return () => dispatch(clearMessage());
  }, [navigate, dispatch, token]);

  return { handleFieldChange, userLogin, message, errors };
};

export default LoginLogic;
