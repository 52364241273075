import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import React from "react";
import DataTable from "../../../components/DataTable/DataTable";
import RecomendationLogic from "./RecomendationLogic";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function Recomendation() {
  const logic = RecomendationLogic();
  return (
    <Container>
      <CssBaseline />
      <Paper
        sx={{
          mt: 5,
          p: {
            xs: 1,
            sm: 3,
            lg: 5,
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6">Rekomendasi</Typography>
        </Box>
        {logic.recomendedDorms && (
          <DataTable
            columns={logic.tableColumns}
            data={logic.recomendedDorms}
            actionButtons={logic.tableActionButtons}
            hiddenColumns={logic.hiddenColumns}
          />
        )}
      </Paper>
    </Container>
  );
}

export default Recomendation;
