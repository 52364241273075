import {
  Box,
  Button,
  Chip,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import DormCard from "../../../components/DormCard.js/DormCard";
import OwnerDetailLogic from "./OwnerDetailLogic";
import { Chart } from "react-google-charts";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

export default function OwnerDetail() {
  const logic = OwnerDetailLogic();

  return (
    <Container>
      <CssBaseline />
      <Button
        variant="contained"
        color="secondary"
        sx={{ borderRadius: 5, mt: 5, mb: 2 }}
        onClick={logic.back}
      >
        <KeyboardBackspaceIcon sx={{ marginRight: 2 }} />
        Kembali
      </Button>
      <Grid container spacing={1}>
        <Grid item sm={6}>
          <Paper sx={{ p: 2 }}>
            <Typography sx={{ fontWeight: "bold" }}>Profil</Typography>
            {logic.profile && (
              <>
                <Typography textAlign="center">
                  {logic.profile.user.name}
                </Typography>
                <Typography textAlign="center">
                  ({logic.profile.user.email})
                </Typography>
                <Typography textAlign="center">
                  ({logic.profile.phone})
                </Typography>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item sm={6}>
          <Paper sx={{ p: 2 }}>
            <Typography fontWeight="bold">Deposit</Typography>
            <Typography
              variant="h5"
              sx={{ m: 2, alignItems: "center", display: "flex" }}
            >
              {logic.profile?.user?.role === "admin" ? (
                <Box component="span" sx={{ fontSize: 30, fontWeight: "bold" }}>
                  &infin;
                </Box>
              ) : (
                logic.profile?.remaining
              )}{" "}
              <Chip label="Point" sx={{ marginLeft: 1 }} />
            </Typography>
          </Paper>
        </Grid>
        <Grid item sm={12}>
          {logic.chartData.length > 1 && (
            <Paper sx={{ padding: 2 }}>
              <Chart chartType="BarChart" data={logic.chartData} width="100%" />
            </Paper>
          )}
        </Grid>
      </Grid>
      <Paper sx={{ mt: 3, p: 2 }}>
        <Typography sx={{ fontWeight: "bold", mb: 2 }}>Kost:</Typography>
        <Button variant="contained" onClick={logic.addDorm}>
          Tambah Kost/Kontrakan
        </Button>
        <Grid container sx={{ m: 1 }}>
          {logic.profile &&
            logic.profile.dorms.map((dorm) => (
              <Grid item key={dorm.id} sx={{ m: 1 }}>
                <DormCard
                  key={dorm.id}
                  thumbnail={
                    dorm.photos[0]
                      ? process.env.REACT_APP_HOST +
                        "photos/" +
                        dorm.photos[0].photo
                      : null
                  }
                  name={dorm.name}
                  village={dorm.village}
                  facilities={dorm.facilities}
                  category={dorm.category.category}
                  onClick={() => logic.dormDetail(dorm.id)}
                />
              </Grid>
            ))}
        </Grid>
      </Paper>
    </Container>
  );
}
