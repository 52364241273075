import api from "./Api";
import axios from "axios";
import TokenService from "./TokenService";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
      config.responseType = "blob";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const getAllOwnerVerifications = () => api.get("owner-verification");
const getVerificationHistory = () => api.get("owner-verification-history");
const displayIdCard = (filename) => instance.get("display-id-card/" + filename);
const updateVerification = (id, data) =>
  api.put("owner-verification/" + id, data);

const ownerVerificationService = {
  getAllOwnerVerifications,
  getVerificationHistory,
  displayIdCard,
  updateVerification,
};

export default ownerVerificationService;
