import {
  CLEAR_REGION_DATA,
  MAP_MARKED,
  SET_CITY,
  SET_DISTRICT,
  SET_PROVINCE,
  SET_VILLAGE,
} from "../ActionTypes";

export const setProvince = (code, meta) => ({
  type: SET_PROVINCE,
  payload: { code, meta },
});

export const setCity = (code, meta) => ({
  type: SET_CITY,
  payload: { code, meta },
});

export const setDistrict = (code, meta) => ({
  type: SET_DISTRICT,
  payload: { code, meta },
});

export const setVillage = (code, meta) => ({
  type: SET_VILLAGE,
  payload: { code, meta },
});

export const clearRegionData = () => ({
  type: CLEAR_REGION_DATA,
});

export const markLocation = (payload) => ({
  type: MAP_MARKED,
  payload,
});
