import {
  Alert,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import PersonalDataLogic from "./PersonalDataLogic";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import MailIcon from "@mui/icons-material/Mail";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import FormDialog from "../../../components/FormDialog/FormDialog";

export default function PersonalData() {
  const logic = PersonalDataLogic();
  return (
    <Container sx={{ pt: 5 }}>
      <CssBaseline />
      {logic.userProfile?.owner ? (
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">Profil:</Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <AccountBoxIcon />
                  </ListItemIcon>
                  <ListItemText primary={logic.userProfile.name} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <MailIcon />
                  </ListItemIcon>
                  <ListItemText primary={logic.userProfile.email} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ContactPhoneIcon />
                  </ListItemIcon>
                  <ListItemText primary={logic.userProfile.owner.phone} />
                </ListItem>
              </List>
              <Button variant="contained" onClick={logic.handleShowEditProfile}>
                Edit Profil
              </Button>
            </Paper>
          </Grid>
          <Grid item sm={6}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">Password:</Typography>
              <Button
                variant="contained"
                onClick={logic.handleShowChangePassword}
              >
                Ubah Password
              </Button>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Paper sx={{ p: 2 }}>
          {logic.message.status && (
            <Alert sx={{ width: "100%" }} severity={logic.message.status}>
              {logic.message.text}
            </Alert>
          )}
          <Box>
            <TextField
              onChange={logic.handleFieldChange}
              margin="normal"
              required
              fullWidth
              size="small"
              type="text"
              label="Nama"
              name="Nama"
              value={logic.userProfile?.name}
              disabled
            />
            <TextField
              onChange={logic.handleFieldChange}
              margin="normal"
              required
              fullWidth
              size="small"
              type="text"
              label="Email"
              name="Email"
              value={logic.userProfile?.email}
              disabled
            />
            <TextField
              onChange={logic.handleFieldChange}
              margin="normal"
              required
              fullWidth
              size="small"
              type="number"
              label="Nomor Hp/Whatsapp"
              name="Nomor Hp"
              value={logic.phone}
              autoFocus
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ mb: 3 }}
                onClick={logic.submitData}
              >
                <b>Simpan</b>
              </Button>
            </Box>
          </Box>
        </Paper>
      )}
      <FormDialog
        show={logic.showEditProfile}
        dialogTitle="Edit"
        dialogContentText="Ubah Profil"
        fields={logic.editProfileFields}
        handleFieldChange={logic.handleProfileChange}
        close={logic.handleCloseEditProfile}
        submit={logic.submitUpdateProfile}
      />
      <FormDialog
        show={logic.showChangePassword}
        dialogTitle="Edit"
        dialogContentText="Ubah Password"
        fields={logic.changePasswordFields}
        handleFieldChange={logic.handlePasswordChange}
        close={logic.handleCloseChangePassword}
        submit={logic.submitNewPassword}
      />
      <Snackbar
        open={logic.showSnackbar}
        autoHideDuration={6000}
        onClose={logic.handleCloseSnackbar}
      >
        <Alert
          onClose={logic.handleCloseSnackbar}
          severity={logic.alert.severity}
          sx={{ width: "100%" }}
        >
          {logic.alert.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}
