import * as ActionTypes from "../ActionTypes";
import userService from "../../services/UserService";

export const loadProfileAction = (navigate) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOADING });
    userService.loadProfile(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: ActionTypes.LOAD_PROFILE_SUCCESS, res });
          res.data.role === "admin" ? navigate("/admin") : navigate("/user");
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: ActionTypes.LOAD_PROFILE_ERROR, res });
        }
      },
      (error) => {
        if (error.data?.message === "Your email address is not verified.") {
          navigate("/user/email-verification");
          dispatch({
            type: ActionTypes.EMAIL_NOT_VERIFIED,
          });
        }
        navigate("/");
        dispatch({ type: ActionTypes.CODE_ERROR });
      }
    );
  };
};

export const reloadProfile = () => (dispatch) => {
  dispatch({ type: ActionTypes.LOADING });
  userService.loadProfile(
    (res) => {
      if (res.hasOwnProperty("success") && res.success === true) {
        dispatch({ type: ActionTypes.LOAD_PROFILE_SUCCESS, res });
      } else if (res.hasOwnProperty("success") && res.success === false) {
        dispatch({ type: ActionTypes.LOAD_PROFILE_ERROR, res });
      }
    },
    (error) => {
      if (error.data?.message === "Your email address is not verified.") {
        dispatch({
          type: ActionTypes.EMAIL_NOT_VERIFIED,
        });
      }
      dispatch({ type: ActionTypes.CODE_ERROR });
    }
  );
};

export const SubmitPersonalData = (data, navigate) => (dispatch) => {
  dispatch({ type: ActionTypes.LOADING });
  userService.submitOwnerData(
    data,
    (res) => {
      dispatch({
        type: ActionTypes.SUBMIT_PERSONAL_DATA_SUCCESS,
        payload: res.message,
      });
      navigate(-1);
    },
    (error) => {
      dispatch({
        type: ActionTypes.SUBMIT_PERSONAL_DATA_ERROR,
        payload: error.message,
      });
    }
  );
};
