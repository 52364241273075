import {
  Container,
  CssBaseline,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialog from "../../../components/DeleteDialog/DeleteDialog";
import FormDialog from "../../../components/FormDialog/FormDialog";
import CategoriesLogic from "./CategoriesLogic";

export default function Categories() {
  const logic = CategoriesLogic();
  return (
    <Container>
      <CssBaseline />
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        <Button
          sx={{ m: 2 }}
          variant="contained"
          onClick={logic.handleOpenAddDialog}
        >
          Tambah Data Kategori
        </Button>
        <Table sx={{ minWidth: 300 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>No. </TableCell>
              <TableCell>Kategori</TableCell>
              <TableCell>Aksi</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logic.categoryList.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell>{row.category}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => logic.handleOpenDeleteDialog(row.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <FormDialog
        show={logic.addDialog}
        close={logic.handleCloseAddDialog}
        dialogTitle="Kategori"
        dialogContentText="Tambah data kategori"
        fields={logic.fields}
        handleFieldChange={logic.handleFieldChange}
        submit={logic.submitNewCategory}
      />

      {/* dialog hapus fasilitas */}
      <DeleteDialog
        title="Hapus"
        text="Kategori ini"
        show={logic.deleteDialog}
        close={logic.handleCloseDeleteDialog}
        confirm={logic.deleteDataCategory}
      />
    </Container>
  );
}
