import api from "./Api";

const getProvinces = (onSuccess, onError) => {
  api
    .get("provinces")
    .then((response) => onSuccess(response.data.data))
    .catch((error) => onError(error));
};

const getCities = (code, onSuccess, onError) => {
  api
    .get("cities/" + code)
    .then((response) => onSuccess(response.data.data))
    .catch((error) => onError(error));
};

const getDistricts = (code, onSuccess, onError) => {
  api
    .get("districts/" + code)
    .then((response) => onSuccess(response.data.data))
    .catch((error) => onError(error));
};

const getVillages = (code, onSuccess, onError) => {
  api
    .get("villages/" + code)
    .then((response) => onSuccess(response.data.data))
    .catch((error) => onError(error));
};

const regionService = {
  getProvinces,
  getCities,
  getDistricts,
  getVillages,
};

export default regionService;
