import React from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import EditDormLogic from "./EditDormLogic";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import PriceDialog from "../../components/PriceDialog/PriceDialog";
import LeafletMap from "../../components/LeafletMap/LeafletMap";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

export default function EditDorm() {
  const { detail } = useSelector((state) => state.dorm);
  const logic = EditDormLogic(detail);

  return (
    <Container>
      <CssBaseline />
      {logic.detail ? (
        <Grid container spacing={1} sx={{ mt: 3 }}>
          <Grid item sm={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ borderRadius: 5 }}
                  onClick={logic.back}
                >
                  <KeyboardBackspaceIcon sx={{ marginRight: 2 }} />
                  Kembali
                </Button>
              </Box>
              <Typography
                variant="h5"
                sx={{ flex: 1, display: "flex", justifyContent: "center" }}
              >
                Edit {detail.name}
              </Typography>
              <Box sx={{ flex: 1 }}></Box>
            </Paper>
          </Grid>
          <Grid item sm={4}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={detail.active === 1 ? true : false}
                    onChange={logic.handleSwitchChange}
                  />
                }
                label={detail.active === 1 ? "Aktif" : "Nonaktif"}
              />
              <FormControl>
                <TextField
                  value={logic.name}
                  label="Nama Kost"
                  variant="standard"
                  onChange={(e) => logic.setName(e.target.value)}
                />
                <TextField
                  sx={{ mt: 2 }}
                  value={logic.address}
                  label="Alamat Lengkap"
                  multiline
                  onChange={(e) => logic.setAddress(e.target.value)}
                />
                <TextField
                  sx={{ my: 2 }}
                  value={logic.phone}
                  size="small"
                  label="No. Hp (Whatsapp)"
                  onChange={(e) => logic.setPhone(e.target.value)}
                />
              </FormControl>
              <Paper
                elevation={3}
                sx={{ mt: 1, p: 1, display: "flex", flexDirection: "column" }}
              >
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Fasilitas:
                </Typography>
                <Grid container>
                  {logic.facilityList?.map((facility) => (
                    <FormControlLabel
                      key={facility.id}
                      control={<Checkbox />}
                      label={facility.facility}
                      checked={logic.facilities.includes(facility.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          logic.selectFacility(facility.id);
                        } else {
                          logic.deselectFacility(facility.id);
                        }
                      }}
                    />
                  ))}
                </Grid>
              </Paper>
              <Paper
                elevation={3}
                sx={{ mt: 1, p: 1, display: "flex", flexDirection: "column" }}
              >
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Kategori:
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={logic.category}
                  onChange={(e) => logic.setCategory(e.target.value)}
                >
                  <Grid container>
                    {logic.categoryList?.map((category) => (
                      <FormControlLabel
                        key={category.id}
                        value={category.id}
                        control={<Radio />}
                        label={category.category}
                      />
                    ))}
                  </Grid>
                </RadioGroup>
              </Paper>
              <Paper
                elevation={3}
                sx={{ mt: 1, p: 1, display: "flex", flexDirection: "column" }}
              >
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Harga:
                </Typography>
                {detail.prices.map((price) => (
                  <Box
                    key={price.id}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body2">
                      {price.max_price
                        ? new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          }).format(price.price) +
                          " - " +
                          new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          }).format(price.max_price)
                        : new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          }).format(price.price)}
                      /{price.unit}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        logic.handleOpenDeletePriceDialog(price.id)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Box>
                  <Button
                    variant="contained"
                    onClick={logic.handleOpenPriceDialog}
                  >
                    Tambahkan Harga
                  </Button>
                </Box>
              </Paper>
              <Paper
                elevation={3}
                sx={{ mt: 1, p: 1, display: "flex", flexDirection: "column" }}
              >
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Keterangan:
                </Typography>
                <TextField
                  value={logic.term}
                  label="Keterangan"
                  placeholder="Keterangan"
                  multiline
                  onChange={(e) => logic.setTerm(e.target.value)}
                />
              </Paper>
            </Paper>
          </Grid>
          <Grid item sm={8}>
            <Paper sx={{ p: 2, mt: 2 }}>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Foto:
              </Typography>
              <ImageList cols={3} gap={8}>
                {detail.photos.map((photo, index) => (
                  <ImageListItem key={index}>
                    <img
                      src={process.env.REACT_APP_HOST + "photos/" + photo.photo}
                      alt={index}
                      loading="lazy"
                    />
                    <Box sx={{ alignSelf: "center" }}>
                      <IconButton
                        onClick={() => logic.handleOpenDeleteDialog(photo.id)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </ImageListItem>
                ))}
              </ImageList>
              <Grid container>
                {logic.selectedImage.map((item, index) => {
                  return (
                    <Box key={index} sx={{ alignSelf: "center", my: 1 }}>
                      <Chip variant="outlined" size="small" label={item.name} />
                      <IconButton
                        onClick={() => logic.removeSelectedImage(item)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  );
                })}
              </Grid>
              <input
                ref={logic.inputRef}
                accept="image/*"
                type="file"
                onChange={logic.imageChange}
                hidden
              />
              <IconButton
                onClick={logic.handleUpload}
                sx={{ m: 2, p: 2, border: 1 }}
              >
                <AddPhotoAlternateIcon />
              </IconButton>
            </Paper>
            <Paper
              sx={{
                my: 2,
                p: 2,
                height: "430px",
                maxHeight: "63%",
              }}
            >
              <Typography variant="body2" sx={{ mb: 2 }}>
                Lokasi:
              </Typography>
              <LeafletMap />
            </Paper>
          </Grid>
          <Grid item sm={12} sx={{ my: 2 }}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{ alignSelf: "center" }}
                onClick={logic.submit}
              >
                Simpan
              </Button>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <PriceDialog
        show={logic.showPriceDialog}
        close={logic.handleClosePriceDialog}
        save={logic.savePrice}
      />
      <DeleteDialog
        show={logic.showDeleteDialog}
        close={logic.handleCloseDeleteDialog}
        title="Hapus Foto"
        text="Foto ini"
        confirm={logic.deleteImage}
      />
      <DeleteDialog
        title="Hapus"
        text="Harga"
        show={logic.showDeletePriceDialog}
        close={logic.handleCloseDeletePriceDialog}
        confirm={logic.deletePrice}
      />
      <Snackbar
        open={logic.showSnackbar}
        autoHideDuration={6000}
        onClose={logic.handleCloseSnackbar}
      >
        <Alert
          onClose={logic.handleCloseSnackbar}
          severity={logic.message.severity}
          sx={{ width: "100%" }}
        >
          {logic.message.text}
        </Alert>
      </Snackbar>
    </Container>
  );
}
