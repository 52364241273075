import api from "./Api";

const storePhoto = (data, onSuccess, onError) => {
  api
    .post("photo", data)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const showPhoto = (dorm_id, onSuccess, onError) => {
  api
    .get("photo/" + dorm_id)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const deletePhoto = (photo_id, onSuccess, onError) => {
  api
    .delete("photo/" + photo_id)
    .then((response) => onSuccess(response.data))
    .catch((error) => onError(error));
};

const photoService = {
  storePhoto,
  showPhoto,
  deletePhoto,
};

export default photoService;
