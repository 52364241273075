import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewCategory, clearCategoryData, deleteCategory, getAllCategories } from "../../../redux/actions/CategoryAction";
import { clearMessage } from "../../../redux/actions/MessageAction";

const CategoriesLogic = () => {
  const dispatch = useDispatch();
  const { categoryList } = useSelector((state) => state.category);
  const [addDialog, setAddDialog] = useState(false);
  const [category, setCategory] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedCategory, setselectedCategory] = useState(null);

  const fields = [
    {
      id: "Category",
      name: "Nama Kategori",
      type: "Text",
    },
  ];

  const handleFieldChange = (e) => {
    setCategory(e.target.value);
  };

  const handleOpenAddDialog = () => {
    setAddDialog(true);
  };
  const handleCloseAddDialog = () => {
    setAddDialog(false);
  };

  const handleOpenDeleteDialog = (id) => {
    setselectedCategory(id);
    setDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialog(false);
    dispatch(clearMessage());
  };

  const submitNewCategory = () => {
    dispatch(
      addNewCategory(category, () => {
        handleCloseAddDialog();
        dispatch(getAllCategories());
      })
    );
  };

  const deleteDataCategory = () => {
    dispatch(
      deleteCategory(selectedCategory, () => {
        handleCloseDeleteDialog();
        dispatch(getAllCategories());
      })
    );
  };

  useEffect(() => {
    dispatch(getAllCategories());
    return () => {
      dispatch(clearCategoryData());
    };
  }, [dispatch]);

  return {
    categoryList,
    addDialog,
    deleteDialog,
    fields,
    handleCloseAddDialog,
    handleOpenAddDialog,
    handleCloseDeleteDialog,
    handleOpenDeleteDialog,
    submitNewCategory,
    deleteDataCategory,
    handleFieldChange,
  };
};

export default CategoriesLogic;
