import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";
import { useDispatch, useSelector } from "react-redux";
import { markLocation } from "../../redux/actions/RegionAction";

function LeafletMap() {
  const { meta, pinpoint } = useSelector((state) => state.region);
  const [coordinate, setCoordinate] = useState([-6.2087634, 106.845599]);
  const dispatch = useDispatch();

  const MyMarker = () => {
    useMapEvents({
      click(e) {
        dispatch(markLocation({ lat: e.latlng.lat, lng: e.latlng.lng }));
        setCoordinate([e.latlng.lat, e.latlng.lng]);
      },
    });
    return pinpoint ? (
      <Marker position={[pinpoint.lat, pinpoint.lng]} draggable={true}>
        <Popup>Lokasi Kost Anda</Popup>
      </Marker>
    ) : null;
  };

  function ChangeView({ center }) {
    const map = useMap();
    map.setView(center, 15);
    return null;
  }

  useEffect(() => {
    if (meta) {
      const parsedMeta = JSON.parse(meta);
      setCoordinate([parseFloat(parsedMeta.lat), parseFloat(parsedMeta.long)]);
    }
    return () => {};
  }, [meta]);

  return (
    <MapContainer
      center={coordinate}
      zoom={15}
      style={{ height: "80%", width: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ChangeView center={coordinate} />
      <MyMarker />
    </MapContainer>
  );
}

export default LeafletMap;
