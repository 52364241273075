import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewFacility,
  clearFacilityData,
  deleteFacility,
  getAllFacilities,
} from "../../../redux/actions/FacilityAction";
import { clearMessage } from "../../../redux/actions/MessageAction";

const FacilitiesLogic = () => {
  const dispatch = useDispatch();
  const { facilityList } = useSelector((state) => state.facility);
  const [addDialog, setAddDialog] = useState(false);
  const [facility, setFacility] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedFacility, setselectedFacility] = useState(null);

  const fields = [
    {
      id: "facility",
      name: "Nama Fasilitas",
      type: "Text",
    },
  ];

  const handleFieldChange = (e) => {
    setFacility(e.target.value);
  };

  const handleOpenAddDialog = () => {
    setAddDialog(true);
  };
  const handleCloseAddDialog = () => {
    setAddDialog(false);
  };

  const handleOpenDeleteDialog = (id) => {
    setselectedFacility(id);
    setDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialog(false);
    dispatch(clearMessage());
  };

  const submitNewFacility = () => {
    dispatch(
      addNewFacility(facility, () => {
        handleCloseAddDialog();
        dispatch(getAllFacilities());
      })
    );
  };

  const deleteDataFacility = () => {
    dispatch(
      deleteFacility(selectedFacility, () => {
        handleCloseDeleteDialog();
        dispatch(getAllFacilities());
      })
    );
  };

  useEffect(() => {
    dispatch(getAllFacilities());
    return () => {
      dispatch(clearFacilityData());
    };
  }, [dispatch]);

  return {
    facilityList,
    addDialog,
    deleteDialog,
    fields,
    handleCloseAddDialog,
    handleOpenAddDialog,
    handleCloseDeleteDialog,
    handleOpenDeleteDialog,
    submitNewFacility,
    deleteDataFacility,
    handleFieldChange,
  };
};

export default FacilitiesLogic;
