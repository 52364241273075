import {
  CLEAR_MESSAGE,
  CODE_ERROR,
  SET_MESSAGE,
  SUBMIT_PERSONAL_DATA_ERROR,
  SUBMIT_PERSONAL_DATA_SUCCESS,
} from "../ActionTypes";

const initialState = {
  text: null,
  status: null,
};
const MessageReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return { text: payload.text, status: payload.status };

    case CLEAR_MESSAGE:
      return { text: null, status: null };

    case CODE_ERROR:
      return {
        text: "Terjadi kesalahan",
        status: "error",
      };

    case SUBMIT_PERSONAL_DATA_SUCCESS:
      return {
        text: payload,
        status: "success",
      };

    case SUBMIT_PERSONAL_DATA_ERROR:
      return {
        text: payload,
        status: "error",
      };

    default:
      return state;
  }
};

export default MessageReducer;
