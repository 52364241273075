import { LOADING } from "../ActionTypes";

const initState = {
  isLoading: false,
};

const LoadingReducer = (state = initState, action) => {
  const { type } = action;

  switch (type) {
    case LOADING:
      return { loading: true };

    default:
      return { loading: false };
  }
};

export default LoadingReducer;
