import { Box, Container, CssBaseline, Paper, Typography } from "@mui/material";
import React from "react";
import DormLogic from "./DormLogic";
import DataTable from "../../../components/DataTable/DataTable";

export default function Dorms() {
  const logic = DormLogic();
  return (
    <Container>
      <CssBaseline />
      <Paper
        sx={{
          mt: 5,
          p: {
            xs: 1,
            sm: 3,
            lg: 5,
          },
        }}
      >
        <Box>
          <Typography variant="h6" textAlign="center" sx={{ my: 3 }}>
            Data Kost/Kontrakan
          </Typography>
        </Box>
        {logic.dormList && (
          <DataTable
            columns={logic.columns}
            data={logic.dormList}
            actionButtons={logic.tableActionButtons}
            hiddenColumns={logic.hiddenColumns}
          />
        )}
      </Paper>
    </Container>
  );
}
