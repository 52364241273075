import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router";
import Loader from "./components/Loader/Loader";

export default function AdminOutlet() {
  const { userProfile } = useSelector((state) => state.userDetails);
  return userProfile ? (
    userProfile.role === "admin" ? (
      <Outlet />
    ) : (
      <Navigate to="/" />
    )
  ) : (
    <>
      <CssBaseline />
      <Loader />
    </>
  );
}
