import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";
import { useSelector } from "react-redux";

function LeafletMapStatic() {
  const { detail } = useSelector((state) => state.dorm);
  const parsedPoint = JSON.parse(detail.map_point);
  const coordinate = [parseFloat(parsedPoint.lat), parseFloat(parsedPoint.lng)];

  return (
    <MapContainer
      center={coordinate}
      zoom={15}
      style={{ height: "100%", width: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={coordinate}>
        <Popup>{detail.name}</Popup>
      </Marker>
    </MapContainer>
  );
}

export default LeafletMapStatic;
