import React from "react";
import { Navigate, Outlet } from "react-router";

export default function PrivateOutlet() {
  return localStorage.getItem("user-token") ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
}
